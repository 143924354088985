import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import images from "../../Assets/Images/breed.png";
import { UpdateBreednameApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";

const EditModal = ({
  head,
  detail,
  button,
  placeholder,
  onHide,
  setIsEdit,
  value,
  editIndexer,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [inputWeightValue, setInputWeightValue] = useState("");
  const [errorMsgEmpty, setErrorMsgEmpty] = useState(false)
  const [weightErrMsg, setWeightErrMsg] = useState('');
  const WeightRegex = "[+-]?([0-9]*[.])?[0-9]+";

  useEffect(() => {
    setInputValue(value ? value.breed_name : null);
    setInputWeightValue(value ? value.ideal_weight : null);
  }, [value]);

  const addValue = (e) => {
    setInputValue(e.target.value);
  };

  const handleChangeBreedIdealWeight = (e) => {
    setInputWeightValue(e.target.value);
  }

  const updateBreed = async (e) => {
    e.preventDefault();
    let check = true;
    if (!inputValue) {
      setErrorEmpty(true);
      setErrorMsg("Breed Name should not be empty");
      check = false;
    } else if (inputValue) {
      if (/^\s+$/.test(inputValue) === true) {
        setErrorEmpty(true);
        setErrorMsg("Breed Name should not be empty");
        check = false;
      } else {
        setErrorEmpty(false);
      }
    } else {
      setErrorMsg(false);
    }

    if (!inputWeightValue) {
      setErrorMsgEmpty(true)
      setWeightErrMsg("Ideal weight should not be empty")
      check = false;
    } else if (inputWeightValue) {
      if (Number.isInteger(inputWeightValue) || /\d+\.\d+/.test(inputWeightValue)) {
        setErrorMsgEmpty(false)
        setWeightErrMsg("")
      } else {
        setErrorMsgEmpty(true)
        setWeightErrMsg("Ideal weight should be number")
        check = false;
      }
    } else {
      setErrorMsgEmpty(false)
      setWeightErrMsg("")
    }

    if (check === true) {
      const data = {
        breed_name: inputValue,
        breed_cat_id: value.breed_cat_id,
        ideal_weight: parseFloat(inputWeightValue)
      };
      const Response = await UpdateBreednameApi(data);
      console.log(Response);
      if (Response.status === 200) {
        toast.success(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        toast.error(Response.message);
      }
    }
  };

  return (
    <>
      <div className="Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <img
              src={closeButton}
              className="closeButton"
              alt="closeButton"
              onClick={onHide}
            />
            <div className="modal-image">
              <img
                loading="lazy"
                src={images}
                alt="category image"
                className="modal_Head_Image"
              />
            </div>
            <h3>{head}</h3>
            <Form onSubmit={updateBreed}>
              <div className="Modal-input">
                <input
                  type="text"
                  placeholder={placeholder}
                  maxLength={100}
                  value={inputValue}
                  onChange={addValue}
                ></input>
              </div>
              <p className="err-msg">{errorEmpty ? errorMsg : ""}</p>
              <br></br>
              <div className="Modal-input">
                <input
                  placeholder="Enter breed ideal weight"
                  value={inputWeightValue}
                  onChange={handleChangeBreedIdealWeight}
                ></input>
              </div>
              <p className="err-msg">{errorMsgEmpty ? weightErrMsg : ""}</p>
              <button className="modal_Button" onSubmit={updateBreed}>
                Update
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EditModal;
