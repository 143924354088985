import React, { useState } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import BackArrow from "../../Components/BackArrow/BackArrow";
import Search from "../../Components/Search/Search";
import SearchBarResults from "../../Components/Search/SearchBarResults";
import BreedsTable from "../../Components/Table/BreedsTable";
import CreateModal from "../../Components/Modals/CreateModal";
// import modalImg from "../../Assets/Images/breed.png";
import modalImg from "../../Assets/Images/breed.png";

const Breeds = () => {
  const [modalShow, setModalShow] = useState(false);
  // search
  const [search, setSearch] = useState("");
  return (
    <div>
      <Navigation />
      <div className="breeds-Main">
        <BackArrow to="/dashboard" />
        <div className="breeds-Div">
          <h3>Breeds</h3>
          <div className="search-modal">
            <Search SearchValFuc={setSearch} SearchValue={search} />
            <button
              className="create"
              variant="primary"
              onClick={() => {
                setModalShow(true);
              }}
            >
              + ADD BREEDS
            </button>
          </div>
          <SearchBarResults SearchValue={search} />
        </div>
        <BreedsTable SearchValue={search} />
      </div>
      <CreateModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        head="Add a new breed"
        detail="You can add the new breed here"
        placeholder="Enter breed"
        images={modalImg}
      />
    </div>
  );
};

export default Breeds;
