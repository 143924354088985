import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaMailBulk, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import deleteIcon from "../../Assets/Images/delete icon.png";
import editIcon from "../../Assets/Images/edit icon.svg";
import Pagination from "../Pagination/Pagination";
import { UsersTableApi } from "../../Utils/API/UsersTableApi";
import { Switch } from "antd";
import Loader from "../../Components/Loader/Loader";
import {
  RegisteredUsersActiveDeactiveApi,
  RegisteredUsersApi,
} from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Modals } from "../Modals/Modals";
import sortUp from "../../Assets/Images/sortup.png";
import sortDown from "../../Assets/Images/sortdown.png";
import { EmailPopup } from "../Modals/EmailPopup";

const UsersTable = ({ SearchValue, getuserId }) => {
  const [modalShow, setModalShow] = useState(false);

  const [indexer, setIndexer] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [registeredUsersData, setRegisteredUsersDatalist] = useState([]);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [editIndexer, setEditIndexer] = useState();
  const [sortClick, setSortClick] = useState(1);
  const [loading, setLoading] = useState(false);
  const [switchIcon, setSwitchIcon] = useState(0);
  const [indexAction, setIndexAction] = useState("");
  const [tableHeadClick, setTableHeadClick] = useState("first_name");
  const [firstName, setFirstName] = useState("");
  const [showEmailpopup, setShowEmailpopup] = useState(false);
  const [userId, setUserId] = useState(" ");

  useEffect(() => {
    if (isDelete == true) {
      onChangeSwitch();
      setModalShow(false);
      setIsDelete(false);
    }
  }, [isDelete]);

  // console.log("registeredUsersData", registeredUsersData);

  const onChangeSwitch = async () => {
    const data = {
      user_id: indexer,
      action: indexAction,
    };
    console.log(data, "data", switchIcon);
    const Response = await RegisteredUsersActiveDeactiveApi(data);
    console.log(Response, "switchresponse");
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  useEffect(() => {
    setLoading(true);
    RigisteredUsersListdata();
  }, [pageNumber, sortClick, SearchValue, tableHeadClick]);

  const RigisteredUsersListdata = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      sort_type: sortClick,
      column_name: tableHeadClick,
      search_value: SearchValue,
    };
    const Response = await RegisteredUsersApi(data);
    setLoading(false);
    setRegisteredUsersDatalist(Response.data.activity_type);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="TableMain">
        <table>
          <thead>
            <tr>
              <th className="tablehead">
                <div className="tableheaders">
                  First Name
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                        setTableHeadClick("first_name");
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                        setTableHeadClick("first_name");
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">
                  Last Name
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                        setTableHeadClick("last_name");
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                        setTableHeadClick("last_name");
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">
                  Email
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                        setTableHeadClick("email");
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                        setTableHeadClick("email");
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders center_Col">Subscription</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Billing Cycle</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Location</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders center_Col">Pet Count</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders center_Col">Email Sent</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Action</div>
              </th>
            </tr>
          </thead>
          <tbody id="TBody">
            {registeredUsersData?.length > 0 ? (
              registeredUsersData.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.first_name}</td>
                    <td>{item.last_name}</td>
                    <td>{item.email}</td>
                    <td>{item.subscription}</td>
                    <td>{item.billing_cycle}</td>
                    <td>Not provided</td>
                    <td className="center_Col">{item.pet_count}</td>
                    <td className="email_Links">
                      <FaMailBulk
                        onClick={() => {
                          setShowEmailpopup(true);
                          setUserId(item.user_id);
                        }}
                      />
                    </td>
                    <td className="TableButtons">
                      <Switch
                        checked={item.action === 0 ? false : true}
                        onChange={() => {
                          setModalShow(true);
                          setIndexer(item.user_id);
                          setIndexAction(item.action === 0 ? 1 : 0);
                          setFirstName(item.first_name);
                        }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={7}>
                <p className="NoDataAvailable">No data available!</p>
              </td>
            )}
          </tbody>
        </table>
        {totalPageCount > 10 ? (
          <>
            <Pagination
              Limit={limit}
              SetPageNumber={setPageNumber}
              TotalPageCount={totalPageCount}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <Modals
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsDelete={setIsDelete}
        head={
          indexAction === 0
            ? "Are you sure you want to disable " + firstName + "?"
            : "Are you sure you want to enable " + firstName + "?"
        }
      />
      <EmailPopup
        show={showEmailpopup}
        onHide={() => setShowEmailpopup(false)}
        showAgain={() => setShowEmailpopup(true)}
        getuserId={userId}
      />
    </>
  );
};

export default UsersTable;
