import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import images from "../../Assets/Images/toy.png";
import { UpdateColorApi, UpdateToysApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";

const EditModal = ({
  head,
  detail,
  button,
  placeholder,
  onHide,
  setIsEdit,
  value,
  editIndexer,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");


  useEffect(() => {
    setInputValue(value ? value.title : null);
  }, [value]);

  const addValue = (e) => {
    setInputValue(e.target.value);
  };
  

  const updateToy = async (e) => {
    e.preventDefault();
    let check = true;
    if (!inputValue) {
      setErrorEmpty(true);
      setErrorMsg("Toy Name should not be empty");
      check = false;
    } else if (inputValue) {
      if (/^\s+$/.test(inputValue) === true) {
        setErrorEmpty(true);
        setErrorMsg("Toy Name should not be empty");
        check = false;
      } else {
        setErrorEmpty(false);
      }
    } else {
      setErrorMsg(false);
    }

  if (check === true) {
    const data = {
      title: inputValue,
      toy_category_id: value.toy_category_id,
    };
    const Response = await UpdateToysApi(data);

    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.error(Response.message);
    }
  }
  };

  return (
    <>
    <div className="Modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            src={closeButton}
            className="closeButton"
            alt="closeButton"
            onClick={onHide}
          />
          <div className="modal-image">
            <img
              loading="lazy"
              src={images}
              alt="category image"
              className="modal_Head_Image"
            />
          </div>
          <h3>{head}</h3>
          <Form onSubmit={updateToy}>
          <div className="Modal-input">
            <input
              type="text"
              placeholder={placeholder}
              maxLength={50}
              value={inputValue}
              onChange={addValue}
            ></input>
          </div>
          <p className="err-msg">{errorEmpty ? errorMsg : ""}</p>
              <button className="modal_Button" onSubmit={updateToy}>
                Update
              </button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
    </>
  );
};

export default EditModal;
