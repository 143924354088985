import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import deleteIcon from "../../Assets/Images/delete icon.png";
import editIcon from "../../Assets/Images/edit icon.svg";
import { FoodTableApi } from "../../Utils/API/FoodTableApi";
import Pagination from "../Pagination/Pagination";
import { DeleteFoodApi, ViewFoodApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Modals } from "../Modals/Modals";
import EditModal from "../Modals/EditFood";
import Loader from "../../Components/Loader/Loader";
import sortUp from "../../Assets/Images/sortup.png";
import sortDown from "../../Assets/Images/sortdown.png";

const FoodTable = ({ SearchValue }) => {
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [indexer, setIndexer] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [foodDatalist, setfoodDatalist] = useState([]);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [editIndexer, setEditIndexer] = useState();
  const [sortClick, setSortClick] = useState(1);
  const [loading, setLoading] = useState(false);
  const [foodName, setFoodName] = useState("");

  useEffect(() => {
    if (isDelete == true) {
      handleDelete(indexer);
      setModalShow(false);
      setIsDelete(false);
    }
  }, [isDelete]);

  useEffect(() => {
    setLoading(true);
    FoodListdata();
  }, [pageNumber, sortClick, SearchValue]);

  // console.log("sortClick",sortClick)
  const FoodListdata = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      sort_type: sortClick,
      column_name: "title",
      search_value: SearchValue,
    };
    const Response = await ViewFoodApi(data);
    setLoading(false);
    setfoodDatalist(Response.data.food_category);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
  };

  const handleDelete = async () => {
    const data = {
      food_cat_id: indexer,
    };
    const Response = await DeleteFoodApi(data);
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    else if (Response.status === 400){
      toast.error(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleFoodname = async (payload) => {
    const data = {
      food_cat_id: payload,
    };
    // const Response = await EditPlaceholderFoodApi(data)
  };
  const handleClickEdit = (data) => {
    console.log("data", data);
    setEditModalShow(true);
    handleFoodname(data.food_cat_id);
    setEditIndexer(data);
  };

  const handleEditHide = () => {
    setEditModalShow(false);
    window.location.reload();
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="TableMain">
        <table>
          <thead>
            <tr>
              <th className="tablehead">
                <div className="tableheaders">
                  Food List
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody id="TBody">
            {foodDatalist?.length > 0 ? (
              foodDatalist.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.food_name}</td>
                    <td>
                      <div className="table-action">
                        <img
                          loading="lazy"
                          title="Delete"
                          src={deleteIcon}
                          onClick={() => {
                            setModalShow(true);
                            setIndexer(item.food_cat_id);
                            setFoodName(item.food_name);
                          }}
                        />
                        <img
                          loading="lazy"
                          title="Edit"
                          src={editIcon}
                          onClick={() => {
                            handleClickEdit(item);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={2}>
                <p className="NoDataAvailable">No data available!</p>
              </td>
            )}
          </tbody>
        </table>
      </div>
      {totalPageCount > 10 ? (
        <>
          <Pagination
            Limit={limit}
            SetPageNumber={setPageNumber}
            TotalPageCount={totalPageCount}
          />
        </>
      ) : (
        <></>
      )}
      <Modals
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsDelete={setIsDelete}
        head={"Are you sure you want to delete " + foodName + "?"}
      />
      <EditModal
        show={editModalShow}
        onHide={handleEditHide}
        head="Edit the food name"
        detail="You can edit the new food here"
        // placeholder={foodPlaceholder}
        value={editIndexer}
        editIndexer={editIndexer}
      />
    </>
  );
};

export default FoodTable;
