import React, { useState } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import BackArrow from "../../Components/BackArrow/BackArrow";
import Search from "../../Components/Search/Search";
import image from "../../Assets/Images/star.png";
import SearchBarResults from "../../Components/Search/SearchBarResults";
import FoodTable from "../../Components/Table/FoodTable";
import CreateModal from "../../Components/Modals/CreateFood";
import modalImg from "../../Assets/Images/food.png";

const Food = () => {
  // modal
  const [modalShow, setModalShow] = useState(false);
  // search
  const [search, setSearch] = useState("");
  return (
    <div>
      <Navigation />
      <div className="food-Main">
        <BackArrow to="/toys" />
        <div className="food-Div">
          <h3>Food</h3>
          <div className="search-modal">
            <Search SearchValFuc={setSearch} SearchValue={search} />
            <button
              className="create"
              variant="primary"
              onClick={() => {
                setModalShow(true);
              }}
            >
              + ADD FOOD
            </button>
          </div>
          <SearchBarResults SearchValue={search} />
        </div>
        <FoodTable SearchValue={search} />
      </div>
      <CreateModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        head="Add a new food"
        detail="You can add the new food here"
        placeholder="Enter food"
        images={modalImg}
      />
    </div>
  );
};

export default Food;
