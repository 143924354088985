import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import images from "../../Assets/Images/color.png";
import { UpdateColorApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";

const EditModal = ({
  head,
  detail,
  button,
  placeholder,
  onHide,
  setIsEdit,
  value,
  editIndexer,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setInputValue(value ? value.color_name : null);
  }, [value]);

  const addValue = (e) => {
    setInputValue(e.target.value);
  };

  const updateColor = async () => {
    const data = {
      color_id: value.color_id,
      color_name: inputValue,
    };
    const Response = await UpdateColorApi(data);
    console.log(Response);
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.error(Response.message);
    }
  };

  return (
    <div className="Modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            src={closeButton}
            className="closeButton"
            alt="closeButton"
            onClick={onHide}
          />
          <div className="modal-image">
            <img
              loading="lazy"
              src={images}
              alt="category image"
              className="modal_Head_Image"
            />
          </div>
          <h3>{head}</h3>
          {/* <h4>{detail}</h4> */}
          <div className="Modal-input">
            <input
              type="text"
              placeholder={placeholder}
              maxLength={50}
              value={inputValue}
              onChange={addValue}
            ></input>
          </div>
          <button
            className="modal_Button"
            onClick={
              inputValue && inputValue.length > 0
                ? () => {
                    updateColor(inputValue);
                  }
                : () => {}
            }
          >
            Update
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditModal;
