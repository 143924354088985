import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import images from "../../Assets/Images/personality.png";
import edit_Icon from "../../Assets/Images/editIcon.png";
import { UpdateColorApi, UpdatePersonalityApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import EasyCrop from "../Cropper/EasyCrop";
import imageCompression from "browser-image-compression";
import swal from "sweetalert";
import editButton from "../../Assets/Images/edit icon.svg";

const EditModal = ({
  head,
  detail,
  button,
  placeholder,
  onHide,
  setIsEdit,
  value,
  editIndexer,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [inputImage, setInputImage] = useState("");
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const [cropImage, setCropImage] = useState("");
  const [croppedCompressedImage, setCroppedCompressedImage] = useState("");
  const [petThumbnailImage, setPetThumbnailImage] = useState("");

  useEffect(() => {
    setInputValue(value ? value.personality_name : null);
    setInputImage(value ? value.pet_personality_image : null);
  }, [value]);

  const addValue = (e) => {
    setInputValue(e.target.value);
  };

  const updateColor = async () => {
    const data = {
      personality_id: value.personality_id,
      personality_name: inputValue,
      // pet_personality_image: cropImage,
      pet_personality_image: {
        data: !cropImage ? " " : cropImage,
      },
    };
    const Response = await UpdatePersonalityApi(data);
    console.log(Response.pet_personality_image, "dattata");
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.error(Response.message);
    }
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(
      ` originalFile size --> ${imageFile.size} Bytes into ${
        imageFile.size / 1024 / 1024
      } MB`
    );

    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(` compressedFile size --> ${compressedFile.size} Bytes`); // smaller than maxSizeMB
      const compressedSize = compressedFile.size / 1024 / 1024;
      console.log(`${compressedSize} MB`, compressedFile.name);

      console.log(compressedFile); // write your own logic
      const baseval = await imageCompression.getDataUrlFromFile(compressedFile);
      setCroppedCompressedImage(baseval);
      //  console.log(baseval,"baseval")

      console.log(image, compressedFile.name, "get name");
      if (compressedSize > options.maxSizeMB) {
        swal({
          title: "Error!",
          text: "File too Big, please select a file less than 1MB",
          icon: "error",
          button: "Ok",
        });
      } else {
        // setShow(true);
        setImage(baseval);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="Modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            src={closeButton}
            className="closeButton"
            alt="closeButton"
            onClick={onHide}
          />
          <div className="modal-image">
            <img
              loading="lazy"
              src={images}
              alt="category image"
              className="modal_Head_Image"
            />
          </div>
          <h3>{head}</h3>
          {/* <h4>{detail}</h4> */}

          <div className="App">
            <header className="App-header">
              <label
                className={
                  cropImage ? "hide_CoverImage_Holder" : "_coverImage-holder"
                }
              >
                <img src={inputImage} className="petimage" />
                <img src={edit_Icon} className="edit_Icon" />
                <input
                  type="file"
                  name="cover"
                  onChange={handleImageUpload}
                  accept="img/*"
                  style={{ display: "none" }}
                />
              </label>
              <EasyCrop
                image={image}
                setCropImage={setCropImage}
                closeButton={editButton}
              />
            </header>
          </div>

          <div className="Modal-input">
            <input
              type="text"
              placeholder={placeholder}
              maxLength={100}
              value={inputValue}
              onChange={addValue}
            ></input>
          </div>
          <button
            className="modal_Button"
            onClick={
              inputValue && inputValue.length > 0
                ? () => {
                    updateColor(inputValue);
                  }
                : () => {}
            }
          >
            Update
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditModal;
