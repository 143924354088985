import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import { EditActivityMessagesApi, EditActivityMotivationMessagesApi, UpdateColorApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";
import images from "../../Assets/Images/breed.png";

const EditActivityMotivation = ({
  head,
  detail,
  button,
  placeholder,
  onHide,
  setIsEdit,
  value,
  editIndexer,
  // images,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setInputValue(value ? value.activity_message : null);
  }, [value]);

  const addValue = (e) => {
    setInputValue(e.target.value);
    console.log(e.target.value,"e.target.value")
  };

  const updateActivityMessage = async (e) => {
    e.preventDefault();
    let check = true;
    if (!inputValue) {
      setErrorEmpty(true);
      setErrorMsg("Activity Message should not be empty");
      check = false;
    } else if (inputValue) {
      if (/^\s+$/.test(inputValue) === true) {
        setErrorEmpty(true);
        setErrorMsg("Activity Message should not be empty");
        check = false;
      } else {
        setErrorEmpty(false);
      }
    } else {
      setErrorMsg(false);
    }

    console.log(inputValue,"inputValueinputValueinputValue")

    if (check === true) {
    const data = {
      activity_message: inputValue,
      activity_id: value.activity_id,
    };
    const Response = await EditActivityMotivationMessagesApi(data);
    console.log(Response);
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      toast.error(Response.message);
    }
  }
  };

  return (
    <div className="Modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            src={closeButton}
            className="closeButton"
            alt="closeButton"
            onClick={onHide}
          />
          <div className="modal-image">
            <img
              loading="lazy"
              src={images}
              alt="category image"
              className="modal_Head_Image"
            />
          </div>
          <h3>{head}</h3>
          <Form onSubmit={updateActivityMessage}>
            <div className="Modal-input">
              <input
                type="text"
                placeholder={placeholder}
                maxLength={100}
                value={inputValue}
                onChange={addValue}
              ></input>
            </div>
            <p className="err-msg">{errorEmpty ? errorMsg : ""}</p>
            <button className="modal_Button" onSubmit={updateActivityMessage}>
              Update
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditActivityMotivation;
