import React, { useState } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import modalImg from "../../Assets/Images/activities.png";
import Search from "../../Components/Search/Search";
import BackArrow from "../../Components/BackArrow/BackArrow";
import SearchBarResults from "../../Components/Search/SearchBarResults";
import CreateActivityMessage from "../../Components/Modals/CreateActivityMessage";
import ActivityMessageTable from "../../Components/Table/AcitivityMessageTable";
import CreateActivityMotivationMsg from "../../Components/Modals/CreateActivittyMotivationMsg";
import CreateActivityMotivationMsgTable from "../../Components/Table/ActivityMotivationMsgTable";

const ActivityMotivation = () => {
  // modal
  const [modalShow, setModalShow] = useState(false);
  // search
  const [search, setSearch] = useState("");
  // active class
  const [activeClass, setActiveClass] = useState("health");

  const activeClassFun = (tab) => {
    setActiveClass(tab);

    const box = document.getElementById("colored_Box");

    if (tab === "activity") {
      box.style.left = "48%";
    } else if (tab === "health") {
      box.style.left = "2%";
    }
  };

  return (
    <div>
      <Navigation />
      <div className="User-Main message_Tabs">
        <BackArrow to="/activities" />
        <div className="User-Div">
          <h3>Activity Motivation</h3>
          <div className="search-modal">
            <Search SearchValFuc={setSearch} SearchValue={search} />
            <button
              className="create health_Activity"
              variant="primary"
              onClick={() => {
                setModalShow(true);
              }}
            >
              + ADD ACTIVITY MOTIVATION
            </button>
          </div>
          <SearchBarResults SearchValue={search} />
        </div>
        <CreateActivityMotivationMsgTable SearchValue={search}  />
        <CreateActivityMotivationMsg
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
          head="Add a new activity motivation message"
          detail="You can add the new message here"
          placeholder="Enter message"
          images={modalImg}
        />
      </div>
    </div>
  );
};

export default ActivityMotivation;
