import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import images from "../../Assets/Images/dog bow.png";
import { AdminRoleApi, UpdateBreednameApi, UpdateFoodApi, UpdateUsersApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const EditModal = ({
  head,
  detail,
  button,
  placeholder,
  onHide,
  setIsEdit,
  value,
  editIndexer,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);

  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("**********");
  const [adminRole, setAdminRole] = useState("");
  const [adminChangedRole, setAdminChangedRole] = useState(1);

  const [errorNameEmpty, setErrorNameEmpty] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorEmailEmpty, setErrorEmailEmpty] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorPasswordEmpty, setErrorPasswordEmpty] = useState(false);
  const [errorMsgPassword, setErrorMsgPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const showPasswordHandler = () => {
    setShowPassword(false);
  };
  const hidePasswordHandler = () => {
    setShowPassword(true);
  };

  useEffect(() => {
    setAdminName(value ? value.admin_name : null);
    setAdminEmail(value ? value.admin_email : null);
  }, [value]);

  const handleChaneAdminName = (e) => {
    e.preventDefault();
    setAdminName(e.target.value);
  };

  useEffect(() => {
    AdminRoleData();
  }, []);

  const AdminRoleData = async () => {
    const Response = await AdminRoleApi();
    setAdminRole(Response.data);
  };

  const handleChangeAdminRole = (e) => {
    e.preventDefault();
    setAdminChangedRole(e.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    let Emailformat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let check = true;
    if (!adminName) {
      setErrorNameEmpty(true);
      setErrorMsgName("Admin Name should not be empty");
      check = false;
    } else if (adminName) {
      if (/^\s+$/.test(adminName) === true) {
        setErrorNameEmpty(true);
        setErrorMsgName("Admin Name should not be empty");
        check = false;
      } else {
        setErrorNameEmpty(false);
      }
    } else {
      setErrorMsgName(false);
    }


    const data = {
      admin_name: adminName,
      admin_id:value.admin_id,
      admin_email:adminEmail,
      admin_role:1
    };
    const Response = await UpdateUsersApi(data);
    console.log(Response);
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      toast.error(Response.message);
    }
  };

  return (
    <div className="Modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            src={closeButton}
            className="closeButton"
            alt="closeButton"
            onClick={onHide}
          />
          <div className="modal-image">
            <img
              loading="lazy"
              src={images}
              alt="category image"
              className="modal_Head_Image"
            />
          </div>
          <h3>{head}</h3>
          <h4>{detail}</h4>
          {/* <div className="Modal-input">
            <input
              type="text"
              placeholder={placeholder}
              maxLength={50}
              value={adminName}
              onChange={addValue}
            ></input>
          </div> */}
        <Form onSubmit={handleSubmit}>
          <Row>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Admin user name</Form.Label>
                    <Form.Control
                      placeholder="Enter Admin user name"
                      value={adminName}
                      onChange={handleChaneAdminName}
                    />
                    <p className="err-msg">
                      {errorNameEmpty ? errorMsgName : ""}
                    </p>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Admin user email</Form.Label>
                    <Form.Control
                      placeholder="Enter Admin user name"
                      autoComplete="off"
                      value={adminEmail}
                      disabled
                    />
                    <p className="err-msg">
                      {errorEmailEmpty ? errorMsgEmail : ""}
                    </p>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3 toggle_Password">
                    <Form.Label>Admin user password</Form.Label>
                    <Form.Control
                      placeholder="Enter Admin user name"
                      type={showPassword === true ? "text" : "password"}
                      autoComplete="new-password"
                      value={adminPassword}
                      disabled
                    />
                    {adminPassword !== "" ? (
                      showPassword === true ? (
                        <FaEye
                          onClick={showPasswordHandler}
                          className="Faeye"
                        />
                      ) : (
                        <FaEyeSlash
                          onClick={hidePasswordHandler}
                          className="Faeye"
                        />
                      )
                    ) : (
                      <></>
                    )}
                    <p className="err-msg">
                      {errorPasswordEmpty ? errorMsgPassword : ""}
                    </p>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Admin role</Form.Label>
                    <Form.Select onChange={(e) => handleChangeAdminRole(e)}>
                      {adminRole?.length > 0
                        ? adminRole.map((item, index) => {
                            return (
                              <option value={item.role_id}>
                                {item.role_name}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <button
                className="modal_Button"
                onSubmit={handleSubmit}
                // onClick={
                //   adminName.length > 0 && adminEmail.length > 0 && adminPassword.length > 0 ? handleSubmit : ""}
              >

                {button}
              </button>
              </Form>
          {/* <button
          className="modal_Button"
            onClick={
              adminName && adminName.length > 0
                ? () => {
                    updateBreed(adminName);
                  }
                : () => {}
            }
          >
            Edit
          </button> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditModal;
