import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import deleteIcon from "../../Assets/Images/delete icon.png";
import editIcon from "../../Assets/Images/edit icon.svg";
import Pagination from "../Pagination/Pagination";
import {
  DeletePersonalityApi,
  PersonalityListingApi,
} from "../../Utils/API/ApiCall";
import { useState } from "react";
import { Modals } from "../Modals/Modals";
import { ToastContainer, toast } from "react-toastify";
import EditModal from "../Modals/EditPersonality";
import Loader from "../../Components/Loader/Loader";
import sortUp from "../../Assets/Images/sortup.png";
import sortDown from "../../Assets/Images/sortdown.png";

const PersonalityTable = ({ SearchValue }) => {
  const [personalityDataList, setPersonalityDataList] = useState([]);
  const [indexer, setIndexer] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [editIndexer, setEditIndexer] = useState();
  const [sortClick, setSortClick] = useState(1);
  const [editModalShow, setEditModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [personalityName, setPersonalityName] = useState("");

  useEffect(() => {
    if (isDelete == true) {
      handleDelete(indexer);
      setModalShow(false);
      setIsDelete(false);
    }
  }, [isDelete]);

  useEffect(() => {
    setLoading(true);
    PersonalityList();
  }, [pageNumber, sortClick, SearchValue]);

  const PersonalityList = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      sort_type: sortClick,
      column_name: "created_at",
      search_value: SearchValue,
    };
    const Response = await PersonalityListingApi(data);
    setLoading(false);
    setPersonalityDataList(Response.data.personality);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
  };

  const handleDelete = async () => {
    const data = {
      personality_id: indexer,
    };
    const Response = await DeletePersonalityApi(data);
    console.log(Response, "del;ete");
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    else if (Response.status === 400){
      toast.error(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handlePersonalityname = async (payload) => {
    const data = {
      personality_id: payload,
    };
    // const Response = await EditPlaceholderBreedApi(data)
  };
  const handleClickEdit = (data) => {
    console.log("data", data);
    setEditModalShow(true);
    handlePersonalityname(data.personality_id);
    setEditIndexer(data);
  };

  const handleEditHide = () => {
    setEditModalShow(false);
    window.location.reload();
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="TableMain">
        <table>
          <thead>
            <tr>
              <th className="tablehead">
                <div className="tableheaders">
                  Personality List
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Icons</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody id="TBody">
            {personalityDataList?.length > 0 ? (
              personalityDataList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.personality_name}</td>
                    <td>
                      <img
                        loading="lazy"
                        src={item.pet_personality_image}
                        className="personality-img-table"
                      />
                    </td>
                    <td>
                      <div className="table-action">
                        <img
                          loading="lazy"
                          title="Delete"
                          src={deleteIcon}
                          onClick={() => {
                            setModalShow(true);
                            setIndexer(item.personality_id);
                            setPersonalityName(item.personality_name);
                          }}
                        />
                        <img
                          loading="lazy"
                          title="Edit"
                          src={editIcon}
                          onClick={() => {
                            handleClickEdit(item);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={3}>
                <p className="NoDataAvailable">No data available!</p>
              </td>
            )}
          </tbody>
        </table>
        {totalPageCount > 10 ? (
          <>
            <Pagination
              Limit={limit}
              SetPageNumber={setPageNumber}
              TotalPageCount={totalPageCount}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <Modals
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsDelete={setIsDelete}
        head={
          "Are you sure you want to delete " +
          personalityName +
          " from the list?"
        }
      />
      <EditModal
        show={editModalShow}
        onHide={handleEditHide}
        head="Edit the personality name"
        detail="You can edit the new personality here"
        // placeholder={breedPlaceholder}
        value={editIndexer}
        editIndexer={editIndexer}
      />
    </>
  );
};

export default PersonalityTable;
