import { executeGET, executePost } from "../API/ServicesMethods";

// SignInApi
export const SignInApi = async (data) => {
  try {
    const SignIn = await executePost("fleatiger/admin", data);
    const SignInData = SignIn.data ? SignIn.data : [];
    return SignInData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// BreedList
export const BreedListApi = async (data) => {
  try {
    const BreedList = await executePost("fleatiger/admin/breedlist", data);
    const BreedListData = BreedList.data ? BreedList.data : [];
    return BreedListData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

// AddBreedApi
export const AddBreedApi = async (data) => {
    try {
      const AddBreed = await executePost("fleatiger/admin/addbreed", data);
      const AddBreedData = AddBreed.data ? AddBreed.data : [];
      return AddBreedData;
    } catch (error) {
      console.log("error",error)
      return false;
    }
  };


// DeleteBreedApi
export const DeleteBreedApi = async (data) => {
    try {
      const DeleteBreed = await executePost("fleatiger/admin/deletebreed", data);
      const DeleteBreedData = DeleteBreed.data ? DeleteBreed.data : [];
      return DeleteBreedData;
    } catch (error) {
      console.log("error",error)
      return false;
    }
  };


// EditPlaceholderBreedApi
export const EditPlaceholderBreedApi = async (data) => {
    try {
      const EditPlaceholderBreed = await executePost("fleatiger/admin/editbreed", data);
      const EditPlaceholderBreedData = EditPlaceholderBreed.data ? EditPlaceholderBreed.data : [];
      return EditPlaceholderBreedData;
    } catch (error) {
      console.log("error",error)
      return false;
    }
  };



// UpdateBreedNameApi
export const UpdateBreednameApi = async (data) => {
    try {
      const UpdateBreedname = await executePost("fleatiger/admin/updatebreed", data);
      const UpdateBreednameData = UpdateBreedname.data ? UpdateBreedname.data : [];
      return UpdateBreednameData;
    } catch (error) {
      console.log("error",error)
      return false;
    }
  };


// ColorListingApi
export const ColorListingApi = async (data) => {
  try {
    const ColorListing = await executePost("fleatiger/admin/viewcolors", data);
    const ColorListingData = ColorListing.data ? ColorListing.data : [];
    return ColorListingData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// DeleteColorApi
export const DeleteColorApi = async (data) => {
  try {
    const DeleteColor = await executePost("fleatiger/admin/deletecolor", data);
    const DeleteColorData = DeleteColor.data ? DeleteColor.data : [];
    return DeleteColorData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// AddColorApi
export const AddColorApi = async (data) => {
  try {
    const AddColor = await executePost("fleatiger/admin/addcolor", data);
    const AddColorData = AddColor.data ? AddColor.data : [];
    return AddColorData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// UpdateColorApi
export const UpdateColorApi = async (data) => {
  try {
    const UpdateColor = await executePost("fleatiger/admin/updatecolor", data);
    const UpdateColorData = UpdateColor.data ? UpdateColor.data : [];
    return UpdateColorData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// PersonalityListingApi
export const PersonalityListingApi = async (data) => {
  try {
    const PersonalityListing = await executePost("fleatiger/admin/personalitylist", data);
    const PersonalityListingData = PersonalityListing.data ? PersonalityListing.data : [];
    return PersonalityListingData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// AddPersonalityApi
export const AddPersonalityApi = async (data) => {
  try {
    const AddPersonality = await executePost("fleatiger/admin/addpersonality", data);
    const AddPersonalityData = AddPersonality.data ? AddPersonality.data : [];
    return AddPersonalityData;
  } catch (error) {
    console.log("AddPersonalityApi error",error)
    return false;
  }
};


// DeletePersonalityApi
export const DeletePersonalityApi = async (data) => {
  try {
    const DeletePersonality = await executePost("fleatiger/admin/deletepersonality", data);
    const DeletePersonalityData = DeletePersonality.data ? DeletePersonality.data : [];
    return DeletePersonalityData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// UpdatePersonalityApi
export const UpdatePersonalityApi = async (data) => {
  try {
    const UpdatePersonality = await executePost("fleatiger/admin/updatepersonality", data);
    const UpdatePersonalityData = UpdatePersonality.data ? UpdatePersonality.data : [];
    return UpdatePersonalityData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

// ViewToysApi
export const ViewToysApi = async (data) => {
  try {
    const ViewToys = await executePost("fleatiger/admin/toylist", data);
    const ViewToysData = ViewToys.data ? ViewToys.data : [];
    return ViewToysData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

// AddToysApi
export const AddToysApi = async (data) => {
  try {
    const AddToys = await executePost("fleatiger/admin/addtoy", data);
    const AddToysData = AddToys.data ? AddToys.data : [];
    return AddToysData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

// DeleteToysApi
export const DeleteToysApi = async (data) => {
  try {
    const DeleteToys = await executePost("fleatiger/admin/deletetoy", data);
    const DeleteToysData = DeleteToys.data ? DeleteToys.data : [];
    return DeleteToysData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// UpdateToysApi
export const UpdateToysApi = async (data) => {
  try {
    const UpdateToys = await executePost("fleatiger/admin/updatetoy", data);
    const UpdateToysData = UpdateToys.data ? UpdateToys.data : [];
    return UpdateToysData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


// ViewFoodApi
export const ViewFoodApi = async (data) => {
  try {
    const ViewFood = await executePost("fleatiger/admin/foodlist", data);
    const ViewFoodData = ViewFood.data ? ViewFood.data : [];
    return ViewFoodData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

// AddFoodApi
export const AddFoodApi = async (data) => {
  try {
    const AddFood = await executePost("fleatiger/admin/addfood", data);
    const AddFoodData = AddFood.data ? AddFood.data : [];
    return AddFoodData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//DeleteFoodApi
export const DeleteFoodApi = async (data) => {
  try {
    const DeleteFood = await executePost("fleatiger/admin/deletefood", data);
    const DeleteFoodData = DeleteFood.data ? DeleteFood.data : [];
    return DeleteFoodData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//UpdateFoodApi
export const UpdateFoodApi = async (data) => {
  try {
    const UpdateFood = await executePost("fleatiger/admin/updatefood", data);
    const UpdateFoodData = UpdateFood.data ? UpdateFood.data : [];
    return UpdateFoodData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//ViewActivityApi
export const ViewActivityApi = async (data) => {
  try {
    const ViewActivity = await executePost("fleatiger/admin/viewactivity", data);
    const ViewActivityData = ViewActivity.data ? ViewActivity.data : [];
    return ViewActivityData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//AddActivityApi
export const AddActivityApi = async (data) => {
  try {
    const AddActivity = await executePost("fleatiger/admin/addactivity", data);
    const AddActivityData = AddActivity.data ? AddActivity.data : [];
    return AddActivityData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//DeleteActivityApi
export const DeleteActivityApi = async (data) => {
  try {
    const DeleteActivity = await executePost("fleatiger/admin/deleteactivity", data);
    const DeleteActivityData = DeleteActivity.data ? DeleteActivity.data : [];
    return DeleteActivityData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//UpdateActivityApi
export const UpdateActivityApi = async (data) => {
  try {
    const UpdateActivity = await executePost("fleatiger/admin/updateactivity", data);
    const UpdateActivityData = UpdateActivity.data ? UpdateActivity.data : [];
    return UpdateActivityData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//ViewAdminUsersApi
export const ViewAdminUsersApi = async (data) => {
  try {
    const ViewAdminUsers = await executePost("fleatiger/admin/userlist", data);
    const ViewAdminUsersData = ViewAdminUsers.data ? ViewAdminUsers.data : [];
    return ViewAdminUsersData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//ViewAdminUsersApi
export const RegisteredUsersApi = async (data) => {
  try {
    const RegisteredUsers = await executePost("fleatiger/admin/registereduserlist", data);
    const RegisteredUsersData = RegisteredUsers.data ? RegisteredUsers.data : [];
    return RegisteredUsersData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//Registered Users Activate / Deactivate
export const RegisteredUsersActiveDeactiveApi = async (data) => {
  try {
    const RegisteredUsers = await executePost("fleatiger/admin/useraction", data);
    const RegisteredUsersData = RegisteredUsers.data ? RegisteredUsers.data : [];
    return RegisteredUsersData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//AdminRole
export const AdminRoleApi = async (data) => {
  try {
    const AdminRole = await executePost("fleatiger/admin/getadminrole", data);
    const AdminRoleData = AdminRole.data ? AdminRole.data : [];
    return AdminRoleData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//AddAdminRole
export const AddAdminRoleApi = async (data) => {
  try {
    const AddAdminRole = await executePost("fleatiger/admin/adduser", data);
    const AddAdminRoleData = AddAdminRole.data ? AddAdminRole.data : [];
    return AddAdminRoleData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//DeleteAdminUsersApi
export const DeleteUsersApi = async (data) => {
  try {
    const DeleteUsers = await executePost("fleatiger/admin/deleteuser", data);
    const DeleteUsersData = DeleteUsers.data ? DeleteUsers.data : [];
    return DeleteUsersData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//UpdateAdminUsersApi
export const UpdateUsersApi = async (data) => {
  try {
    const UpdateUsers = await executePost("fleatiger/admin/updateuser", data);
    const UpdateUsersData = UpdateUsers.data ? UpdateUsers.data : [];
    return UpdateUsersData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//ForgotPasswordApi
export const ForgotPasswordApi = async (data) => {
  try {
    const ForgotPassword = await executePost("fleatiger/admin/forgot-password", data);
    const ForgotPasswordData = ForgotPassword.data ? ForgotPassword.data : [];
    return ForgotPasswordData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//OTPVerifyApi
export const OTPVerifyApi = async (data) => {
  try {
    const OTPVerify = await executePost("fleatiger/admin/verify-admin-otp", data);
    const OTPVerifyData = OTPVerify.data ? OTPVerify.data : [];
    return OTPVerifyData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//ResetPasswordApi
export const ResetPasswordApi = async (data) => {
  try {
    const ResetPassword = await executePost("fleatiger/admin/reset-password", data);
    const ResetPasswordData = ResetPassword.data ? ResetPassword.data : [];
    return ResetPasswordData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//DashboardApi
export const DashboardApi = async (data) => {
  try {
    const Dashboard = await executePost("fleatiger/admin/dashboard", data);
    const DashboardData = Dashboard.data ? Dashboard.data : [];
    return DashboardData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//PreviousEmailApi
export const PreviousEmailApi = async (data) => {
  try {
    const PreviousEmail = await executePost("fleatiger/admin/user-email-list", data);
    const PreviousEmailData = PreviousEmail.data ? PreviousEmail.data : [];
    return PreviousEmailData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//PreviousEmailTemplateApi
export const PreviousEmailTemplateApi = async (data) => {
  try {
    const PreviousEmailTemplate = await executePost("fleatiger/admin/user-email-template", data);
    const PreviousEmailTemplateData = PreviousEmailTemplate.data ? PreviousEmailTemplate.data : [];
    return PreviousEmailTemplateData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//SelectStatusDropdownApi
export const SelectStatusDropdownApi = async (data) => {
  try {
    const SelectStatusDropdown = await executePost("fleatiger/admin/notification-status", data);
    const SelectStatusDropdownData = SelectStatusDropdown.data ? SelectStatusDropdown.data : [];
    return SelectStatusDropdownData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//SelectUserDropdownApi
export const SelectUserDropdownApi = async (data) => {
  try {
    const SelectUserDropdown = await executePost("fleatiger/admin/notification-users", data);
    const SelectUserDropdownData = SelectUserDropdown.data ? SelectUserDropdown.data : [];
    return SelectUserDropdownData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//SendNotificationApi
export const SendNotificationApi = async (data) => {
  try {
    const SendNotification = await executePost("fleatiger/admin/save-notification", data);
    const SendNotificationData = SendNotification.data ? SendNotification.data : [];
    return SendNotificationData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//AllLogsApi
export const AllLogsApi = async (data) => {
  try {
    const AllLogs = await executePost("fleatiger/admin/userlogs", data);
    const AllLogsData = AllLogs.data ? AllLogs.data : [];
    return AllLogsData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//ViewActivityMessagesApi
export const ViewActivityMessagesApi = async (data) => {
  try {
    const ViewActivityMessages = await executePost("fleatiger/admin/view-activity-message", data);
    const ViewActivityMessagesData = ViewActivityMessages.data ? ViewActivityMessages.data : [];
    return ViewActivityMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//DeleteActivityMessagesApi
export const DeleteActivityMessagesApi = async (data) => {
  try {
    const DeleteActivityMessages = await executePost("fleatiger/admin/delete-activity-message", data);
    const DeleteActivityMessagesData = DeleteActivityMessages.data ? DeleteActivityMessages.data : [];
    return DeleteActivityMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//AddActivityMessagesApi
export const AddActivityMessagesApi = async (data) => {
  try {
    const AddActivityMessages = await executePost("fleatiger/admin/add-activity-message", data);
    const AddActivityMessagesData = AddActivityMessages.data ? AddActivityMessages.data : [];
    return AddActivityMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//EditActivityMessagesApi
export const EditActivityMessagesApi = async (data) => {
  try {
    const EditActivityMessages = await executePost("fleatiger/admin/update-activity-message", data);
    const EditActivityMessagesData = EditActivityMessages.data ? EditActivityMessages.data : [];
    return EditActivityMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//ViewHealthMessagesApi
export const ViewHealthMessagesApi = async (data) => {
  try {
    const ViewHealthMessages = await executePost("fleatiger/admin/view-health-message", data);
    const ViewHealthMessagesData = ViewHealthMessages.data ? ViewHealthMessages.data : [];
    return ViewHealthMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//AddHealthMessagesApi
export const AddHealthMessagesApi = async (data) => {
  try {
    const AddHealthMessages = await executePost("fleatiger/admin/add-health-message", data);
    const AddHealthMessagesData = AddHealthMessages.data ? AddHealthMessages.data : [];
    return AddHealthMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//DeleteHealthMessagesApi
export const DeleteHealthMessagesApi = async (data) => {
  try {
    const DeleteHealthMessages = await executePost("fleatiger/admin/delete-health-message", data);
    const DeleteHealthMessagesData = DeleteHealthMessages.data ? DeleteHealthMessages.data : [];
    return DeleteHealthMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//EditHealthMessagesApi
export const EditHealthMessagesApi = async (data) => {
  try {
    const EditHealthMessages = await executePost("fleatiger/admin/update-health-message", data);
    const EditHealthMessagesData = EditHealthMessages.data ? EditHealthMessages.data : [];
    return EditHealthMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//ViewActivity Motivation MessagesApi
export const ViewActivityMotivationMessagesApi = async (data) => {
  try {
    const ViewActivityMotivationMessages = await executePost("fleatiger/admin/view-activity-motivation-message", data);
    const ViewActivityMotivationMessagesData = ViewActivityMotivationMessages.data ? ViewActivityMotivationMessages.data : [];
    return ViewActivityMotivationMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

export const AddActivityMotivationMessagesApi = async (data) => {
  try {
    const AddActivityMotivationMessages = await executePost("fleatiger/admin/add-activity-motivation-message", data);
    const AddActivityMotivationMessagesData = AddActivityMotivationMessages.data ? AddActivityMotivationMessages.data : [];
    return AddActivityMotivationMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};


//Edit Activity Motivation MessagesApi
export const EditActivityMotivationMessagesApi = async (data) => {
  try {
    const EditActivityMessages = await executePost("fleatiger/admin/update-activity-motivation-message", data);
    const EditActivityMessagesData = EditActivityMessages.data ? EditActivityMessages.data : [];
    return EditActivityMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};

//Delete Activity Motivations Api
export const DeleteActivityMotivationsApi = async (data) => {
  try {
    const DeleteActivityMessages = await executePost("fleatiger/admin/delete-activity-motivation-message", data);
    const DeleteActivityMessagesData = DeleteActivityMessages.data ? DeleteActivityMessages.data : [];
    return DeleteActivityMessagesData;
  } catch (error) {
    console.log("error",error)
    return false;
  }
};
