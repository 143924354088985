import React, { useEffect, useState } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import BackArrow from "../../Components/BackArrow/BackArrow";
import Search from "../../Components/Search/Search";
import SearchBarResults from "../../Components/Search/SearchBarResults";
import logsImage from "../../Assets/Images/User Icon FT.svg";
import { Row } from "react-bootstrap";
import { AllLogsApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";

const LogsTable = ({ SearchValue }) => {
  const [logDatalist, setlogDatalist] = useState([]);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  console.log(SearchValue,"SearchValueSearchValue")

  useEffect(() => {
    setLoading(true);
    LogListdata();
  }, [pageNumber, SearchValue]);

  const LogListdata = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      column_name:"created_at",
      search_value: SearchValue,
    };
    const Response = await AllLogsApi(data);
    setLoading(false);
    setlogDatalist(Response.data.user_logs);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
  };


  return (
    <>
 {loading ? <Loader /> : ""}
            <div className="logs_Main">
              {logDatalist.length > 0 ? (
                logDatalist.map((item, i) => {
                  return (
                    <div className="logs_Div" key={i}>
                      <div className="log_Image_Details">
                        <div className="log_Image">
                          <img src={logsImage} alt="logs image" />
                        </div>
                        <div className="log_Details">
                          <p>
                            <b>{item.user_name}</b>
                          </p>
                          <p>{item.log_operation}</p>
                        </div>
                      </div>
                      <div className="log-Time">
                        <p>{item.created_at}</p>
                      </div>
                  </div>
                  );
                })
              ) : (
                <p className="NoDataAvailable">No data available!</p>
              )}
            </div>
            {totalPageCount > 10 ? (
            <>
              <Pagination
                Limit={limit}
                SetPageNumber={setPageNumber}
                TotalPageCount={totalPageCount}
              />
            </>
          ) : (
            <></>
          )}
    </>
  );
};

export default LogsTable;
