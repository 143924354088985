import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import editButton from "../../Assets/Images/edit icon.svg";
import defaultImage from "../../Assets/Images/default-upload.svg";
import { AddPersonalityApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import EasyCrop from "../Cropper/EasyCrop";
import imageCompression from "browser-image-compression";
import swal from "sweetalert";

const CreateModal = ({
  images,
  head,
  detail,
  button,
  placeholder,
  onHide,
  ...props
}) => {
  const [personalityName, setPersonalityName] = useState("");
  const [image, setImage] = useState(null);
  const [cropImage, setCropImage] = useState("");
  const [croppedCompressedImage, setCroppedCompressedImage] = useState("");

  const [theCroppedImage, setTheCroppedImage] = useState();

  // err
  const [personalityErr, setpersonalityErr] = useState('');
  const [personalityErrMsg, setpersonalityErrMsg] = useState('');
  const [personalityImgErr, setpersonalityImgErr] = useState('');
  const [personalityImgErrMsg, setpersonalityImgErrMsg] = useState('');

  // const handleImageUpload = async (e) => {
  //   setImage(URL.createObjectURL(e.target.files[0]));
  //  // console.log(URL,"imageeee")
  // };

  async function handleImageUpload(event) {
    console.log("event", event)
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(
      ` originalFile size --> ${imageFile.size} Bytes into ${imageFile.size / 1024 / 1024
      } MB`
    );

    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(` compressedFile size --> ${compressedFile.size} Bytes`); // smaller than maxSizeMB
      const compressedSize = compressedFile.size / 1024 / 1024;
      console.log(`${compressedSize} MB`, compressedFile.name);

      console.log(compressedFile); // write your own logic
      const baseval = await imageCompression.getDataUrlFromFile(compressedFile);
      setCroppedCompressedImage(baseval);
      //  console.log(baseval,"baseval")

      console.log(image, compressedFile.name, "get name");
      if (compressedSize > options.maxSizeMB) {
        swal({
          title: "Error!",
          text: "File too Big, please select a file less than 1MB",
          icon: "error",
          button: "Ok",
        });
      } else {
        // setShow(true);
        setImage(baseval);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeBreedName = (e) => {
    setPersonalityName(e.target.value);
    if(e.target.value.length <= 0){
      setpersonalityErr(true)
      setpersonalityErrMsg('Personality name should not be empty')
    }else{
      setpersonalityErr(false)
      setpersonalityErrMsg('')
 
      // console.log(e.target.value);
    }
 
  };
  const addBreed = async (e) => {
    try {
      if (personalityName.length <= 0) {
        setpersonalityErr(true)
        setpersonalityErrMsg('Personality name should not be empty')
      } else {
        setpersonalityErr(false)
        setpersonalityErrMsg('')
      }
      if (cropImage.length <= 0) {
        setpersonalityImgErr(true)
        setpersonalityImgErrMsg('Personality image should not be empty')
      }
      else {
        setpersonalityImgErr(false)
        setpersonalityImgErrMsg('')
      }
      if (personalityName.length != 0 && cropImage.length != 0) {
        const data = {
          personality_name: personalityName,
          personality_image: {
            data: !cropImage ? " " : cropImage,
          },
        };
        const Response = await AddPersonalityApi(data);
        if (Response.status == 200) {
          toast.success(Response.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (Response.status == 400) {
          toast.error(Response.message);
        } else {
          toast.error(Response.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }

      }
    } catch (error) {
      console.log("addBreed" + error)
    }

  };

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <img
              src={closeButton}
              className="closeButton"
              alt="closeButton"
              onClick={handleClose}
            />
            <div className="modal-image">
              <img
                loading="lazy"
                src={images}
                alt="category image"
                className="modal_Head_Image"
              />
            </div>
            <h3>{head}</h3>
            {/* <h4>{detail}</h4> */}

            <div className="App">
              <header className="App-header">
                <label
                  className={
                    theCroppedImage
                      ? "hide_CoverImage_Holder"
                      : "_coverImage-holder"
                  }
                >
                  <img src={defaultImage} className="default_Image" />
                  <input
                    type="file"
                    name="cover"
                    onChange={handleImageUpload}
                    accept="img/*"
                    style={{ display: "none" }}
                  />
                </label>
                <EasyCrop
                  image={image}
                  setCropImage={setCropImage}
                  setTheCroppedImage={setTheCroppedImage}
                  closeButton={editButton}
                  setpersonalityImgErr={setpersonalityImgErr}
                />
                <h5>Upload Image</h5>
              </header>
            </div>
            <p className="img-msg">{personalityImgErr ? personalityImgErrMsg : ""}</p>
            <div className="Modal-input">
              <input
                type="text"
                placeholder={placeholder}
                maxLength={100}
                value={personalityName}
                onChange={handleChangeBreedName}
              ></input>
            </div>
            <p className="err-msg">{personalityErr ? personalityErrMsg : ""}</p>
            <button
              className="modal_Button"
              onClick={() =>

                addBreed(personalityName)

              }
            >
              Add
            </button>
            {/* <p className="error-msg">erroe</p><br></br> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CreateModal;
