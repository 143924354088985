import React, { useEffect, useState } from "react";
import { Image, Container, Col, Row, Button } from "react-bootstrap";
import Logo from "../../Assets/Images/login-logo.png";
import { Link, useNavigate } from "react-router-dom";
import forgot from "../../Assets/Images/confused dog.png";
import OTPInput, { ResendOTP } from "otp-input-react";
import { MdArrowBack } from "react-icons/md";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { Form } from "react-bootstrap";
import { ForgotPasswordApi, OTPVerifyApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const [otpValue, setOtpValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorNameEmpty, setErrorNameEmpty] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState("");

  let adminemail = localStorage.getItem("adminemail");

  const handleOTP = (code) => {
    setOtpValue(code);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let check = true;
    if (!otpValue) {
      setErrorNameEmpty(true);
      setErrorMsgName("OTP should not be empty");
      check = false;
    } else if (otpValue) {
      if (otpValue.length != 6) {
        setErrorNameEmpty(true);
        setErrorMsgName("Please fill all OTP fields");
        check = false;
      } else {
        setErrorNameEmpty(false);
      }
    } else {
      setErrorMsgName(false);
    }

    if (check === true) {
      setLoading(true);
      const data = {
        admin_email: adminemail,
        otp: otpValue,
      };
      const Response = await OTPVerifyApi(data);
      if (Response.status == 200) {
        toast.success(Response.message);
        setTimeout(() => {
          navigate("/createnewpassword");
        }, 3000);
        setLoading(false);
      } else if (Response.status == 400) {
        toast.error(Response.message);
        setLoading(false);
      } else {
        toast.error(Response.message);
        setLoading(false);
      }
    }
  };

  const handleResend = async () => {
    setLoading(true);
    setOtpValue("");
    const data = {
      admin_email: adminemail,
    };
    const Response = await ForgotPasswordApi(data);
    console.log(Response, "Response");
    if (Response.status == 200) {
      toast.success(Response.message);
      setTimeout(() => {
        navigate("/verifyOTP");
      }, 3000);
      setLoading(false);
    } else if (Response.status == 400) {
      toast.error(Response.message);
      setLoading(false);
    } else {
      toast.error(Response.message);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="forgot_Password_Main">
        <div className="Forgot_Password public_Routes">
          <Col md="6" className="login-first">
            <Image
              loading="lazy"
              src={forgot}
              className="confused-dog-image"
            ></Image>
          </Col>
          <Col md="6" className="login-second">
            <div className="login_Second_Image">
              <div>
                <Image loading="lazy" src={Logo}></Image>
              </div>
              <p>Verify One-time verification code</p>
            </div>
            <Row className="verify_OTP_Row">
              <div>
                <p>
                  Please enter the one-time verification code sent to your
                  registered email
                </p>
                <p>
                  <i>
                    (If you haven't received the One-time verification code in
                    your inbox, we kindly request you to check your spam
                    folder.)
                  </i>
                </p>
                <Form onSubmit={handleSubmit} className="resend_OTP_Form">
                  <OTPInput
                    value={otpValue}
                    onChange={handleOTP}
                    OTPLength={6}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    otpType="number"
                    className="otp-section"
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none",
                    }}
                  />
                  <p className="err-msg">
                    {errorNameEmpty ? errorMsgName : ""}
                  </p>
                  {/* <Link className="resendotp-section">
                  <p className="forgot-text">Resend Code</p>
                </Link> */}
                  <ResendOTP
                    onResendClick={handleResend}
                    //  timeInterval={500}
                    //  maxTime={5}
                  />
                  <button
                    onSubmit={handleSubmit}
                    variant="primary"
                    className="modal_Button"
                  >
                    Verify
                  </button>
                </Form>
              </div>
              <BackArrow to="/forgotpassword" />
              <p>(Do not refresh the page!)</p>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
