import React, { useState } from "react";
import loginDog from "../../Assets/Images/login.png";
import loginlogo from "../../Assets/Images/login-logo.png";
import Form from "react-bootstrap/Form";
import { Row, Button } from "react-bootstrap";
import ErrorLabel from "../../Components/ErrorLabel/ErrorLabel";
import { Await, useNavigate } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { SignInApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { FaEyeSlash, FaEye } from "react-icons/fa";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function handleChangeName(e) {
    e.preventDefault();
    setEmail(e.target.value);
  }

  function handlePasswordChange(e) {
    e.preventDefault();
    setPassword(e.target.value);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(email, password);
    const data = {
      email: email,
      password: password,
    };
    const Response = await SignInApi(data);
    //console.log( "Login Response",Response.data[0].jwt_token)

    if (Response.status == 200) {
      toast.success(Response.message);
      localStorage.setItem("token", Response.data[0].jwt_token);
      localStorage.setItem("adminname", Response.data[0].admin_name);
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
      setLoading(false);
    } else if (Response.status == 400) {
      toast.error(Response.message);
      setLoading(false);
    } else {
      toast.error(Response.message);
      setLoading(false);
    }
  };

  // const navigateHandler = () => {
  //   navigate("/dashboard");
  // };

  const showPasswordHandler = () => {
    setShowPassword(false);
  };
  const hidePasswordHandler = () => {
    setShowPassword(true);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="login-main">
        <div className="login public_Routes">
          <div className="login-first">
            <img
              loading="lazy"
              src={loginDog}
              alt="login"
              className="login-dog-image"
            />
            <h3>Welcome to Fleatiger</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt
            </p>
          </div>
          <div className="login-second">
            <img
              loading="lazy"
              src={loginlogo}
              alt="logo"
              className="login-logo"
            />
            <h3>Login</h3>
            <Row>
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email address<span className="star-required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Your email address"
                    value={email}
                    onChange={handleChangeName}
                    required
                  />
                  <ErrorLabel />
                </Form.Group>
                <Form.Group className="mb-3 toggle_Password">
                  <Form.Label>
                    Password<span className="star-required">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    type={showPassword === true ? "text" : "password"}
                  />
                  {password !== "" ? (
                    showPassword === true ? (
                      <FaEye onClick={showPasswordHandler} className="Faeye" />
                    ) : (
                      <FaEyeSlash
                        onClick={hidePasswordHandler}
                        className="Faeye"
                      />
                    )
                  ) : (
                    <></>
                  )}
                  <ErrorLabel />
                </Form.Group>
                <div className="password-login-div">
                  <a
                    className="forgot-password-link"
                    onClick={() => {
                      navigate("/forgotpassword");
                    }}
                  >
                    Forgot Password
                  </a>
                  {/* <button onSubmit={onSubmit} className="login-button">
                  Login
                </button> */}
                  <Button
                    type="submit"
                    onSubmit={onSubmit}
                    className="login-button"
                  >
                    Login
                  </Button>
                  {/* <Form.Control type="submit" value="Submit" className="login-button">Login</Form.Control> */}
                  {/* <p className="dont-acc text-center">
                  Don’t have account? <a className="signup-text">Sign Up</a>
                </p> */}
                </div>
              </Form>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
