import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import deleteIcon from "../../Assets/Images/delete icon.png";
import editIcon from "../../Assets/Images/edit icon.svg";
import { ColorsTableApi } from "../../Utils/API/ColorsTableApi";
import Pagination from "../Pagination/Pagination";
import { ColorListingApi, DeleteColorApi } from "../../Utils/API/ApiCall";
import { Modals } from "../Modals/Modals";
import { ToastContainer, toast } from "react-toastify";
import EditModal from "../Modals/EditColor";
import Loader from "../../Components/Loader/Loader";
import sortUp from "../../Assets/Images/sortup.png";
import sortDown from "../../Assets/Images/sortdown.png";

const ColorsTable = ({ SearchValue }) => {
  const [colorsDataList, setColorsDataList] = useState([]);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [indexer, setIndexer] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [sortClick, setSortClick] = useState(1);
  const [editIndexer, setEditIndexer] = useState();
  const [loading, setLoading] = useState(false);
  const [colorsList, setColorsList] = useState("");

  useEffect(() => {
    if (isDelete == true) {
      handleDelete(indexer);
      setModalShow(false);
      setIsDelete(false);
    }
  }, [isDelete]);

  useEffect(() => {
    setLoading(true);
    ColorListData();
  }, [pageNumber, sortClick, SearchValue]);

  const ColorListData = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      sort_type: sortClick,
      column_name: "color_name",
      search_value: SearchValue,
    };
    const Response = await ColorListingApi(data);
    setLoading(false);
    setColorsDataList(Response.data.colors);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
    setPageNumber(Response.data.current_page);
  };

  const handleDelete = async () => {
    const data = {
      color_id: indexer,
    };
    const Response = await DeleteColorApi(data);
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleColorname = async (payload) => {
    const data = {
      color_id: payload,
    };
    // const Response = await EditPlaceholderBreedApi(data)
  };

  const handleClickEdit = (data) => {
    console.log("data", data);
    setEditModalShow(true);
    handleColorname(data.color_id);
    setEditIndexer(data);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="TableMain">
        <table>
          <thead>
            <tr>
              <th className="tablehead">
                <div className="tableheaders">
                  Color List
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody id="TBody">
            {colorsDataList?.length > 0 ? (
              colorsDataList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.color_name}</td>
                    <td>
                      <div className="table-action">
                        <img
                          loading="lazy"
                          title="Delete"
                          src={deleteIcon}
                          onClick={() => {
                            setModalShow(true);
                            setIndexer(item.color_id);
                            setColorsList(item.color_name);
                          }}
                        />
                        <img
                          loading="lazy"
                          title="Edit"
                          src={editIcon}
                          onClick={() => {
                            handleClickEdit(item);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={2}>
                <p className="NoDataAvailable">No data available!</p>
              </td>
            )}
          </tbody>
        </table>
        {totalPageCount > 10 ? (
          <>
            <Pagination
              Limit={limit}
              SetPageNumber={setPageNumber}
              TotalPageCount={totalPageCount}
            />
          </>
        ) : (
          <></>
        )}
        <Modals
          show={modalShow}
          onHide={() => setModalShow(false)}
          setIsDelete={setIsDelete}
          head={"Are you sure you want to delete " + colorsList + "?"}
        />
        <EditModal
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          head="Edit the color"
          detail="You can edit the color here"
          // placeholder={breedPlaceholder}
          value={editIndexer}
          editIndexer={editIndexer}
        />
      </div>
    </>
  );
};

export default ColorsTable;
