import Modal from "react-bootstrap/Modal";
import closebtn from "../../Assets/Images/close-btn.png";
import { PreviousEmailTemplateApi } from "../../Utils/API/ApiCall";
import { useEffect } from "react";
import { useState } from "react";

export const EmailContent = ({ showEmaillist, onHide, getIndex, ...props }) => {

  const [emailSubject, setEmailSubject] = useState([])
  const [emailMessage, setEmailMessage] = useState("")

  useEffect(()=>{
    PreviousEmailTemplateData()
  })
 
const PreviousEmailTemplateData = async () => {
  const data = {
    notification_id:getIndex
  }
  const Response = await PreviousEmailTemplateApi(data)
  //console.log(Response.data,"Response")
  setEmailSubject(Response.data[0].subject)
  setEmailMessage(Response.data[0].message)
}


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-Modal"
    >
      <Modal.Body className="email_Modal">
        <Modal.Header>
          <p>Email details</p>
          <img
            loading="lazy"
            alt="close-btn"
            className="closeButton"
            src={closebtn}
            onClick={onHide}
          />
        </Modal.Header>
        <div className="email_Details">
          {/* <div>
            <h4>Status</h4>
            <p>Status 1</p>
          </div>
          <div>
            <h4>Users</h4>
            <p>Users 1</p>
          </div> */}
          <div className="subject">
            <h4>Subject</h4>
            <p><b>{emailSubject}</b></p>
          </div>
          <div className="email_Content">
            <h4>Email Content</h4>
            <p dangerouslySetInnerHTML={ { __html: emailMessage } }></p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
