import React, { useState, useEffect } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import BackArrow from "../../Components/BackArrow/BackArrow";
import Select from "react-select";
import { Row } from "react-bootstrap";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { SelectStatusDropdownApi,SelectUserDropdownApi, SendNotificationApi } from "../../Utils/API/ApiCall";
import { Col, Form} from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";

const Notifications = () => {
  const [status, setStatus] = useState([]);
  const [adminChangedRole, setAdminChangedRole] = useState(1);
  const [users, setUsers] = useState([]);
  const [adminChangedUsers, setAdminChangedUsers] = useState(1);
  const [subject, setSubject] = useState("");
  const [errorNameEmpty, setErrorNameEmpty] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState("");
  const [content, setContent] = useState("")
  const [errorContentEmpty, setErrorContentEmpty] = useState(false);
  const [errorMsgContent, setErrorMsgContent] = useState("");
  const [loading, setLoading] = useState(false);

  let adminname = localStorage.getItem("adminname");

  useEffect(() => {
    SelectStatusDropdownData();
  }, [adminChangedRole]);

  const handleChangeStatus = (e) => {
    e.preventDefault();
    setAdminChangedRole(e.target.value);
  };

  const SelectStatusDropdownData = async () => {
    const Response = await SelectStatusDropdownApi();
    setStatus(Response.data);
  };


  useEffect(() => {
    SelectUsersDropdownData();
  }, [adminChangedRole]);


  // const handleChangeUser = (e) => {
  //   e.preventDefault();
  //   setAdminChangedUsers(e.target.value);
  // };

  const SelectUsersDropdownData = async () => {
    const data ={
        status:parseInt(adminChangedRole)
    }
    const Response = await SelectUserDropdownApi(data);
    // console.log(Response,"ResponseResponseResponse")
    setUsers(Response.data);
  };


  const handleContentChange = (model) => {
    console.log(model,"model")
    setContent(model);
  };



  const handleChangeSubject = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };


  const handleSelectUsers = (selectedValues) =>{
    let val = selectedValues;

    const userIds = val.map((data)=>{
      return data.user_id
    })
    setAdminChangedUsers(userIds)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let check = true;

    if (!subject) {
      setErrorNameEmpty(true);
      setErrorMsgName("Subject is Required");
      check = false;
    } else if (subject) {
      if (/^\s+$/.test(subject) === true) {
        setErrorNameEmpty(true);
        setErrorMsgName("Subject is Required");
        check = false;
      } else {
        setErrorNameEmpty(false);
      }
    } else {
      setErrorMsgName(false);
    }


  if (!content) {
    setErrorContentEmpty(true);
    setErrorMsgContent("Content is Required");
    check = false;
  } else {
    setErrorMsgContent(false);
  }

  const data ={
    subject:subject,
    user_status:adminChangedRole,
    message:content,
    user_id:adminChangedUsers,
    admin_name:adminname
  }
  const Response = await SendNotificationApi(data)
  console.log(Response,"Response1")

  if (Response.status == 200) {
    toast.success(Response.message);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    setLoading(false);
  } else {
    toast.error(Response.message);
    setLoading(false);
  }
}

// console.log(users,"users")


  return (
    <>
    {loading ? <Loader /> : ""}
    <div>
      <Navigation />
      <div className="Notification-Main">
        <BackArrow to="/users" />
        <div className="Notification-Div">
          <h3>Notifications</h3>
        </div>
        <div className="Notification_Body">
        <Form onSubmit={handleSubmit}>
            <div>
              <Row md="2">
              <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select onChange={(e) => handleChangeStatus(e)}>
                      {status?.length > 0
                        ? status.map((item, index) => {
                            return (
                              <option value={item.status}>
                                {item.status_name}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Select>
                  </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Users</Form.Label>
                    {/* <Form.Select onChange={(e) => handleChangeUser(e)}>
                      {users?.length > 0
                        ? users.map((item, index) => {
                            return (
                              <option value={item.user_id}>
                                {item.user_name}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Select> */}
                    <Multiselect
                      displayValue="user_name"
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={handleSelectUsers}
                      onSearch={function noRefCheck(){}}
                      onSelect={handleSelectUsers}
                      options={users ? users : []}
                      showCheckbox
                    />
                  </Form.Group>
              </Row>
              <div className="label_Input">
              <Form.Group className="mb-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      placeholder="Enter Subject" 
                      value={subject}
                      onChange={handleChangeSubject}
                    />
                    <p className="err-msg">
                      {errorNameEmpty ? errorMsgName : ""}
                    </p>
                  </Form.Group>
              </div>
            </div>
            <div>
              <label>
                Content<span className="required_Star">*</span>
              </label>
              <FroalaEditor
                 model={content}
                 onModelChange={handleContentChange}
                config={{
                  key: "2J1B10dC7B4E4A4G3G3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3A8A4E5D4G3B2B1==", // Pass your key here
                  placeholder: "Type somethimg",
                  charCounterCount: false,
                  autofocus: true,
                  imageUpload: true,
                  imageDefaultAlign: "left",
                  imageDefaultDisplay: "inline-block",
                  // Set max image size to 5MB.
                  imageMaxSize: 5 * 1024 * 1024,
                  // Allow to upload PNG and JPG.
                  imageAllowedTypes: ["jpeg", "jpg", "png"],
                  toolbarButtons: {
                    moreText: {
                      buttons: [
                        "bold",
                        "italic",
                        "underline",
                        "strikeThrough",
                        "subscript",
                        "superscript",
                        "fontFamily",
                        "fontSize",
                        "textColor",
                        "backgroundColor",
                        "inlineClass",
                        "inlineStyle",
                        "clearFormatting",
                      ],
                      align: "left",
                      buttonsVisible: 3,
                    },
                    moreParagraph: {
                      buttons: [
                        "alignLeft",
                        "alignCenter",
                        "formatOLSimple",
                        "alignRight",
                        "alignJustify",
                        "formatOL",
                        "formatUL",
                        "paragraphFormat",
                        "paragraphStyle",
                        "lineHeight",
                        "outdent",
                        "indent",
                        "quote",
                      ],
                      align: "left",
                      buttonsVisible: 3,
                    },
                    moreRich: {
                      buttons: [
                        "insertLink",
                        "insertImage",
                        "insertVideo",
                        "insertTable",
                        "emoticons",
                        "fontAwesome",
                        "specialCharacters",
                        "embedly",
                        "insertFile",
                        "insertHR",
                      ],
                      align: "left",
                      buttonsVisible: 3,
                    },
                    moreMisc: {
                      buttons: [
                        "undo",
                        "redo",
                        "fullscreen",
                        "print",
                        "getPDF",
                        "spellChecker",
                        "selectAll",
                        "html",
                        "help",
                      ],
                      align: "right",
                      buttonsVisible: 2,
                    },
                  },
                }}
              />
              <p className="err-msg">
              {errorContentEmpty ? errorMsgContent : ""}
              </p>
              
            </div>
            <div className="notification_Button">
              <button>Discard</button>
              <button>Send</button>
            </div>
         </Form>
        </div>
      </div>
    </div>
    </>
  );
};

export default Notifications;
