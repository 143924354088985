import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import deleteIcon from "../../Assets/Images/delete icon.png";
import editIcon from "../../Assets/Images/edit icon.svg";
import Pagination from "../Pagination/Pagination";
import { ActivitiesTableApi } from "../../Utils/API/ActivitiesTableApi";
import { DeleteActivityApi, ViewActivityApi } from "../../Utils/API/ApiCall";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Modals } from "../Modals/Modals";
import sortUp from "../../Assets/Images/sortup.png";
import sortDown from "../../Assets/Images/sortdown.png";
import EditModal from "../Modals/EditActivity";

const ActivitiesTable = ({ SearchValue }) => {
  const [activityDatalist, setActivityDatalist] = useState([]);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [editIndexer, setEditIndexer] = useState();
  const [sortClick, setSortClick] = useState(1);
  const [indexer, setIndexer] = useState("");
  const [loading, setLoading] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [activitylist, setActivitylist] = useState("");

  useEffect(() => {
    if (isDelete == true) {
      handleDelete(indexer);
      setModalShow(false);
      setIsDelete(false);
    }
  }, [isDelete]);

  useEffect(() => {
    setLoading(true);
    ActivityListdata();
  }, [pageNumber, sortClick, SearchValue]);

  const ActivityListdata = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      sort_type: sortClick,
      column_name: "activity_type_name",
      search_value: SearchValue,
    };
    const Response = await ViewActivityApi(data);
    setLoading(false);
    // console.log(Response,"dSYgs")
    setActivityDatalist(Response.data.activity_type);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
  };

  const handleDelete = async () => {
    const data = {
      activity_type_id: indexer,
    };
    const Response = await DeleteActivityApi(data);
    console.log(Response, "del;ete");
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleActivityname = async (payload) => {
    const data = {
      activity_type_id: payload,
    };
    // const Response = await EditPlaceholderBreedApi(data)
  };
  const handleClickEdit = (data) => {
    console.log("data", data);
    setEditModalShow(true);
    handleActivityname(data.activity_type_id);
    setEditIndexer(data);
  };

  const handleEditHide = () => {
    setEditModalShow(false);
    window.location.reload();
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="TableMain">
        <table>
          <thead>
            <tr>
              <th className="tablehead">
                <div className="tableheaders">
                  Activities List
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Icons</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody id="TBody">
            {activityDatalist?.length > 0 ? (
              activityDatalist.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.activity_type_name}</td>
                    <td>
                      <img
                        loading="lazy"
                        src={item.activity_image_path}
                        className="personality-img-table"
                      />
                    </td>
                    <td>
                      <div className="table-action">
                        <img
                          loading="lazy"
                          title="Delete"
                          src={deleteIcon}
                          onClick={() => {
                            setModalShow(true);
                            setIndexer(item.activity_type_id);
                            setActivitylist(item.activity_type_name);
                          }}
                        />
                        <img
                          loading="lazy"
                          title="Edit"
                          src={editIcon}
                          onClick={() => {
                            handleClickEdit(item);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={3}>
                <p>No data available!</p>
              </td>
            )}
          </tbody>
        </table>
      </div>
      {totalPageCount > 10 ? (
        <>
          <Pagination
            Limit={limit}
            SetPageNumber={setPageNumber}
            TotalPageCount={totalPageCount}
          />
        </>
      ) : (
        <></>
      )}
      <Modals
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsDelete={setIsDelete}
        head={
          "Are you sure you want to delete " + activitylist + " from the list?"
        }
      />
      <EditModal
        show={editModalShow}
        onHide={handleEditHide}
        head="Edit the acitivity name"
        detail="You can edit the new acitivity here"
        // placeholder={breedPlaceholder}
        value={editIndexer}
        editIndexer={editIndexer}
      />
    </>
  );
};

export default ActivitiesTable;
