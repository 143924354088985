import React, { useEffect, useState } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import BackArrow from "../../Components/BackArrow/BackArrow";
import Search from "../../Components/Search/Search";
import SearchBarResults from "../../Components/Search/SearchBarResults";
import logsImage from "../../Assets/Images/User Icon FT.svg";
import { Row } from "react-bootstrap";
import { AllLogsApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";
import LogsTable from "../../Components/Table/LogsTable";

const AllLogs = ({ SearchValue }) => {
  const [search, setSearch] = useState("");
  // const logDummyData = [
  //   {
  //     name: "Siva",
  //     activity: "has upgraded his plan",
  //     date: "12th October, 2023",
  //     time: "12:20:00 AM",
  //   },
  //   {
  //     name: "Paul",
  //     activity: "has added new activity",
  //     date: "11th October, 2023",
  //     time: "06:10:00 PM",
  //   },
  //   {
  //     name: "Kavitha",
  //     activity: "has added new toy",
  //     date: "10th October, 2023",
  //     time: "12:50:00 AM",
  //   },
  //   {
  //     name: "Nandan",
  //     activity: "has upgraded his plan",
  //     date: "10th October, 2023",
  //     time: "11:20:00 AM",
  //   },
  //   {
  //     name: "Megha",
  //     activity: "has added new food",
  //     date: "09th October, 2023",
  //     time: "10:50:00 AM",
  //   },
  // ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div>
        <Navigation />
        <div className="All-Logs-Main">
          <BackArrow to="/dashboard" />
          <div className="All-Logs-Div">
            <h3>All Logs</h3>
            <div className="search-modal">
              <Search SearchValFuc={setSearch} SearchValue={search} />
            </div>
            <SearchBarResults SearchValue={search} />
          </div>
          <LogsTable SearchValue={search} />
        </div>
      </div>
    </>
  );
};

export default AllLogs;
