import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import deleteImg from "../../Assets/Images/deleteImg.png";
import closebtn from "../../Assets/Images/close-btn.png";

export const Modals = ({ onHide, setIsDelete, head, ...props }) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-Modal"
    >
      <Modal.Body>
        <img
          loading="lazy"
          alt="close-btn"
          className="closeButton"
          src={closebtn}
          onClick={onHide}
        />
        <div className="modal-image">
          <img
            loading="lazy"
            alt="delete"
            className="deleteImg modal_Head_Image"
            src={deleteImg}
          />
        </div>
        <p>{head}</p>
        <div className="modal-buttons">
          <Button onClick={onHide}>No</Button>
          <Button onClick={() => setIsDelete(true)}>Yes</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
