import "./App.css";
import "./Assets/CSS/Style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Breeds from "./Pages/Breeds/Breeds";
import Colors from "./Pages/Colors/Colors";
import Login from "./Pages/Login/Login";
import Personality from "./Pages/Personality/Personality";
import Toys from "./Pages/Toys/Toys";
import Food from "./Pages/Food/Food";
import Activities from "./Pages/Activities/Activities";
import Users from "./Pages/Users/Users";
import "react-toastify/dist/ReactToastify.css";
import Notifications from "./Pages/Notifications/Notifications";
import RequireAuth from "./Utils/RequireAuth";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import VerifyOTP from "./Pages/VerifyOTP/VerifyOTP";
import CreateNewPassword from "./Pages/CreateNewPassword/CreateNewPassword";
import AdminUsers from "./Pages/AdminUsers/AdminUsers";
import AllLogs from "./Pages/AllLogs/AllLogs";
import { ToastContainer, toast } from "react-toastify";
import Health from "./Pages/Messages/Health";
import Activity from "./Pages/Messages/Activity";
import ActivityMotivation from "./Pages/Messages/ActivityMotivation";

function App() {
  const PrivateRoutes = [
    {
      id: 0,
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      id: 1,
      path: "/breeds",
      element: <Breeds />,
    },
    {
      id: 2,
      path: "/colors",
      element: <Colors />,
    },
    {
      id: 3,
      path: "/personality",
      element: <Personality />,
    },
    {
      id: 4,
      path: "/toys",
      element: <Toys />,
    },
    {
      id: 5,
      path: "/food",
      element: <Food />,
    },
    {
      id: 6,
      path: "/activities",
      element: <Activities />,
    },
    {
      id: 7,
      path: "/users",
      element: <Users />,
    },
    {
      id: 8,
      path: "/notifications",
      element: <Notifications />,
    },
    {
      id: 9,
      path: "/adminusers",
      element: <AdminUsers />,
    },
    {
      id: 10,
      path: "/all-logs",
      element: <AllLogs />,
    },
    {
      id: 11,
      path: "/messages/health",
      element: <Health />,
    },
    {
      id: 12,
      path: "/messages/activity",
      element: <Activity />,
    },
    {
      id: 13,
      path: "/messages/activitymotivation",
      element: <ActivityMotivation />,
    },
  ];
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={1}
      />
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/VerifyOTP" element={<VerifyOTP />} />
            <Route
              path="/createnewpassword"
              element={<CreateNewPassword />}
            ></Route>
            {PrivateRoutes.map((routes) => {
              return (
                <Route
                  key={routes.id}
                  path={routes.path}
                  element={<RequireAuth>{routes.element}</RequireAuth>}
                ></Route>
              );
            })}
            {/* <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/breeds" element={<Breeds />} />
          <Route path="/colors" element={<Colors />} />
          <Route path="/personality" element={<Personality />} />
          <Route path="/toys" element={<Toys />} />
          <Route path="/food" element={<Food />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/users" element={<Users />} />
          <Route path="/notifications" element={<Notifications />} /> */}
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
