import React from "react";
import "./Loader.css";
import logo from "../../Assets/Images/logo.png";
import { ThreeDots } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="splashScreen3">
          <img
            loading="lazy"
            src={logo}
            className="logoloader"
          />
          <ThreeDots
            style={{ position: "absolute"}}
            height="40"
            width="30"
            radius="9"
            color="#FE5A3E"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Loader;