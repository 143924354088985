import React, { useEffect, useState } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import { Col, Row } from "react-bootstrap";
import logsImage from "../../Assets/Images/User Icon FT.svg";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DashboardApi } from "../../Utils/API/ApiCall";
import breed from "../../Assets/Images/Navigation icons/breeds.svg";
import personality from "../../Assets/Images/Navigation icons/personality.svg";
import toy from "../../Assets/Images/Navigation icons/toys.svg";
import food from "../../Assets/Images/Navigation icons/food.svg";
import activities from "../../Assets/Images/Navigation icons/activity.svg";
import users from "../../Assets/Images/Navigation icons/app users.svg";
import notifications from "../../Assets/Images/Navigation icons/notifications.svg";
import AdminUsers from "../../Assets/Images/Navigation icons/Admin users.svg";

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardLogs, setDashboardLogs] = useState([]);

  useEffect(() => {
    DashboardApiData();
  }, []);

  const DashboardApiData = async () => {
    const Response = await DashboardApi();
    setDashboardData(Response.data.widget_counter);
    setDashboardLogs(Response.data.user_logs);
  };

  const showAllLogs = () => {
    navigate("/all-logs");
  };

  // const iconArray = [
  //   breed,
  //   personality,
  //   toy,
  //   food,
  //   activities,
  //   users,
  //   notifications,
  //   AdminUsers,
  // ];

  // const restructuredData = iconArray.map((icon, index) => ({
  //   img: icon,
  //   title: dashboardData[index]?.title,
  //   counter: dashboardData[index]?.counter,
  // }));

  return (
    <div>
      <Navigation />
      <div className="dashboard-Main">
        <div className="DashboardCard">
          <Row>
            {dashboardData?.length > 0 ? (
              dashboardData.map((item, index) => {
                return (
                  <Col xs={12} sm={6} md={6} lg={3} key={index}>
                    <div className="Card">
                      <div className="CardDiv">
                        <img
                          loading="lazy"
                          className="cardImage"
                          src={item.icon}
                          alt="cardImage"
                        />
                      </div>
                      <div className="CardDetails">
                        <p className="title">{item.title}</p>
                        <p className="value">{item.counter}</p>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={2}>
                <p className="NoDataAvailable">No data available!</p>
              </td>
            )}
          </Row>
          <Row className="Logs">
            <div className="logs_Main">
              <h3>Logs</h3>
              {dashboardLogs.length > 0 ? (
                dashboardLogs.map((item, i) => {
                  return (
                    <div className="logs_Div" key={i}>
                      <div className="log_Image_Details">
                        <div className="log_Image">
                          <img src={logsImage} alt="logs image" />
                        </div>
                        <div className="log_Details">
                          <p>
                            <b>{item.user_name}</b>
                          </p>
                          <p>{item.log_operation}</p>
                        </div>
                      </div>
                      <div className="log-Time">
                        <p>{item.created_at}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="NoDataAvailable">No data available!</p>
              )}
            </div>
            <div className="load_More">
              <button onClick={showAllLogs}>
                View More
                <FaAngleDoubleDown />
              </button>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
