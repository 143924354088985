import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import { AddBreedApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";

const CreateModal = ({
  images,
  head,
  detail,
  button,
  placeholder,
  onHide,
  idealplaceholder,
  ...props
}) => {
  const [breedName, setBreedName] = useState("");
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [breedIdealWeight, setBreedIdealWeight] = useState("");
  const [idealErrorEmpty, setIdealErrorEmpty] = useState(false);
  const [idealErrorMsg, setIdealErrorMsg] = useState("");

  const handleChangeBreedName = (e) => {
    e.preventDefault();
    setBreedName(e.target.value);
  };

  const handleChangeBreedIdealWeight = (e) => {
    e.preventDefault();
    setBreedIdealWeight(e.target.value);
  };

  const addBreed = async (e) => {
   
    e.preventDefault();
    // let Numberformat = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    let Numberformat = /^(0|[1-9]\d*)(\.\d+)?$/
    let check = true;
    if (!breedName) {
      setErrorEmpty(true);
      setErrorMsg("Breed name should not be empty");
      check = false;
    } else if (breedName) {
      if (/^\s+$/.test(breedName) === true) {
        setErrorEmpty(true);
        setErrorMsg("Breed name should not be empty");
        check = false;
      } else {
        setErrorEmpty(false);
      }
    } else {
      setErrorMsg(false);
    }
    if (!breedIdealWeight) {
      setIdealErrorEmpty(true);
      setIdealErrorMsg("Breed ideal weight should not be empty");
      check = false;
    } else if (breedIdealWeight) {
      if (/^\s+$/.test(breedIdealWeight) === true) {
        setIdealErrorEmpty(true);
        setIdealErrorMsg("Breed ideal weight should not be empty");
        check = false;
       }
        else if  (Numberformat.test(breedIdealWeight) === false){
              setIdealErrorEmpty(true);
              setIdealErrorMsg("Breed ideal weight should not contain letters");
              check = false;
        }
        else{
          setIdealErrorEmpty(false)
          setIdealErrorMsg('')
          check = true;
        }
      }
       else {
        setIdealErrorEmpty(false);
      }



    if (check === true) {
      const data = {
        breed_name: breedName,
        ideal_weight: parseFloat(breedIdealWeight)
      };
      // console.log("data",data)
      const Response = await AddBreedApi(data);
      if (Response.status == 200) {
        toast.success(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (Response.status == 400) {
        toast.error(Response.message);
      } else {
        toast.error(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  const handleClose = () =>{
    window.location.reload();
  }

  return (
    <>
      <div className="Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <img
              src={closeButton}
              className="closeButton"
              alt="closeButton"
              onClick={handleClose}
            />
            <div className="modal-image">
              <img
                loading="lazy"
                src={images}
                alt="category image"
                className="modal_Head_Image"
              />
            </div>
            <h3>{head}</h3>
            <Form onSubmit={addBreed}>
              <div className="Modal-input">
                <input
                  type="text"
                  placeholder={placeholder}
                  maxLength={100}
                  value={breedName}
                  onChange={handleChangeBreedName}
                ></input>
              </div>
              <p className="err-msg">{errorEmpty ? errorMsg : ""}</p>
              <br></br>
              <div className="Modal-input">
                <input
                  type="text"
                  placeholder="Enter breed ideal weight"
                  maxLength={50}
                  value={breedIdealWeight}
                  onChange={handleChangeBreedIdealWeight}
                ></input>
              </div>
              <p className="err-msg">{idealErrorEmpty ? idealErrorMsg : ""}</p>
              <button className="modal_Button" onSubmit={addBreed}>
                Add
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CreateModal;
