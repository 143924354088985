import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import { AddActivityMessagesApi, AddActivityMotivationMessagesApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";

const CreateActivityMotivationMsg = ({
  images,
  head,
  detail,
  button,
  placeholder,
  onHide,
  ...props
}) => {
  const [activityMotivationMessage, setActivityMotivationMessage] = useState("");
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleChangeaddActivityMessage = (e) => {
    e.preventDefault();
    setActivityMotivationMessage(e.target.value);
  };

  const addActivityMessage = async (e) => {
    e.preventDefault();
    let check = true;
    if (!activityMotivationMessage) {
      setErrorEmpty(true);
      setErrorMsg("Activity Message should not be empty");
      check = false;
    } else if (activityMotivationMessage) {
      if (/^\s+$/.test(activityMotivationMessage) === true) {
        setErrorEmpty(true);
        setErrorMsg("Activity Message should not be empty");
        check = false;
      } else {
        setErrorEmpty(false);
      }
    } else {
      setErrorMsg(false);
    }

    if (check === true) {
      const data = {
        activity_message: activityMotivationMessage,
      };
      const Response = await AddActivityMotivationMessagesApi(data);
      if (Response.status == 200) {
        toast.success(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (Response.status == 400) {
        toast.error(Response.message);
      } else {
        toast.error(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  const handleClose = () =>{
    window.location.reload();
  }

  return (
    <>
   <div className="Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <img
              src={closeButton}
              className="closeButton"
              alt="closeButton"
              onClick={handleClose}
            />
            <div className="modal-image">
              <img
                loading="lazy"
                src={images}
                alt="category image"
                className="modal_Head_Image"
              />
            </div>
            <h3>{head}</h3>
            <Form onSubmit={addActivityMessage}>
              <div className="Modal-input">
                <input
                  type="text"
                  placeholder={placeholder}
                  maxLength={100}
                  value={activityMotivationMessage}
                  onChange={handleChangeaddActivityMessage}
                ></input>
              </div>
              <p className="err-msg">{errorEmpty ? errorMsg : ""}</p>
              <button className="modal_Button" onSubmit={addActivityMessage}>
                Add
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CreateActivityMotivationMsg;
