import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import { AddActivityApi, AddPersonalityApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import EasyCrop from "../Cropper/EasyCrop";
import imageCompression from "browser-image-compression";
import swal from "sweetalert";
import editButton from "../../Assets/Images/edit icon.svg";
import defaultImage from "../../Assets/Images/default-upload.svg";

const CreateModal = ({
  images,
  head,
  detail,
  button,
  placeholder,
  onHide,
  ...props
}) => {
  const [activityName, setActivityName] = useState("");
  const [image, setImage] = useState(null);
  const [cropImage, setCropImage] = useState("");
  const [croppedCompressedImage, setCroppedCompressedImage] = useState("");
  const [activityNameErr, setActivityNameErr] = useState(false);
  const [activityNameErrMsg, setActivityNameErrrMsg] = useState('');
  const [activityImgErr, setActivityImgErr] = useState(false);
  const [activityImgErrMsg, setActivityImgErrrMsg] = useState('');


  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(
      ` originalFile size --> ${imageFile.size} Bytes into ${imageFile.size / 1024 / 1024
      } MB`
    );

    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(` compressedFile size --> ${compressedFile.size} Bytes`); // smaller than maxSizeMB
      const compressedSize = compressedFile.size / 1024 / 1024;
      console.log(`${compressedSize} MB`, compressedFile.name);

      console.log(compressedFile); // write your own logic
      const baseval = await imageCompression.getDataUrlFromFile(compressedFile);
      setCroppedCompressedImage(baseval);
      //  console.log(baseval,"baseval")

      console.log(image, compressedFile.name, "get name");
      if (compressedSize > options.maxSizeMB) {
        swal({
          title: "Error!",
          text: "File too Big, please select a file less than 1MB",
          icon: "error",
          button: "Ok",
        });
      } else {
        // setShow(true);
        setImage(baseval);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeBreedName = (e) => {
    setActivityName(e.target.value);
    console.log(e.target.value);
  };
  const addBreed = async (e) => {
    try {
      if (activityName.length <= 0) {
        setActivityNameErr(true)
        setActivityNameErrrMsg('Activity name should not be empty')
      } else {
        setActivityNameErr(false)
        setActivityNameErrrMsg('')
      }
      if (cropImage.length <= 0) {
        setActivityImgErr(true)
        setActivityImgErrrMsg('Activity image should not be empty')
      }
      else {
        setActivityImgErr(false)
        setActivityImgErrrMsg('')
      }
      if (activityName.length != 0 && cropImage.length != 0) {
        const data = {
          activity_type_name: activityName,
          activity_image_path: {
            data: !cropImage ? " " : cropImage,
          },
        };
        const Response = await AddActivityApi(data);
        console.log(Response, "Response Personality");
        if (Response.status == 200) {
          toast.success(Response.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (Response.status == 400) {
          toast.error(Response.message);
        } else {
          toast.error(Response.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (error) {

    }
  };

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <img
              src={closeButton}
              className="closeButton"
              alt="closeButton"
              onClick={handleClose}
            />
            <div className="modal-image">
              <img
                loading="lazy"
                src={images}
                alt="category image"
                className="modal_Head_Image"
              />
            </div>
            <h3>{head}</h3>
            {/* <h4>{detail}</h4> */}

            <div className="App">
              <header className="App-header">
                <label
                  className={
                    cropImage ? "hide_CoverImage_Holder" : "_coverImage-holder"
                  }
                >
                  <img src={defaultImage} className="default_Image" />
                  <input
                    type="file"
                    name="cover"
                    onChange={handleImageUpload}
                    accept="img/*"
                    style={{ display: "none" }}
                  />
                </label>
                <EasyCrop
                  image={image}
                  setCropImage={setCropImage}
                  closeButton={editButton}
                />
                <h5>Upload Image</h5>
              </header>
            </div>
            <p className="img-msg">{activityImgErr ? activityImgErrMsg : ""}</p>
            <div className="Modal-input">
              <input
                type="text"
                placeholder={placeholder}
                maxLength={100}
                value={activityName}
                onChange={handleChangeBreedName}
              ></input>
            </div>
            <p className="err-msg">{activityNameErr ? activityNameErrMsg : ""}</p>
            <button
              className="modal_Button"
              onClick={()=>{addBreed(activityName)}}
            >
              Add
            </button>
            {/* <p className="error-msg">erroe</p><br></br> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CreateModal;
