import React from "react";

const ErrorLabel = ({ ErrorDisplay, color }) => {
  const Color = color === true ? "success" : "star-required";
  return (
    <>
      <p className="error-p">
        <span
          className={`${Color} Errlabel-font_size`}
          style={{ minHeight: "26px" }}
        >
          {ErrorDisplay}
        </span>
      </p>
    </>
  );
};

export default ErrorLabel;
