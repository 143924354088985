import React from "react";
import { Image, Container, Col, Row, Button } from "react-bootstrap";
import Logo from "../../Assets/Images/login-logo.png";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import forgot from "../../Assets/Images/login.png";
import { MdArrowBack } from "react-icons/md";
import ErrorLabel from "../../Components/ErrorLabel/ErrorLabel";
import Loader from "../../Components/Loader/Loader";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useState } from "react";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { ResetPasswordApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [passwordempty, setPasswordempty] = useState(false);
  const [confirmpasswordempty, setConfirmpasswordempty] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [confirmpasswordErrorMsg, setConfirmpasswordErrorMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let adminemail = localStorage.getItem("adminemail");
  let adminid = localStorage.getItem("adminid");

  function handleChangePassword(e) {
    e.preventDefault();
    setPassword(e.target.value);
  }

  function handleChangeConfirmPassword(e) {
    e.preventDefault();
    setConfirmpassword(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let Passwordformat =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

    let check = true;

    //password
    if (password === "") {
      setPasswordempty(true);
      setPasswordErrorMsg("Password is Required");
      check = false;
    } else if (password) {
      if (Passwordformat.test(password) === false) {
        setPasswordempty(true);
        setPasswordErrorMsg(
          "Password should be Min 1 uppercase letter, Min 1 lowercase letter, Min 1 special character, Min 1 number, Min 8 characters, Max 30 characters."
        );
        check = false;
      } else {
        setPasswordempty(false);
      }
    } else {
      setPasswordempty(false);
    }

    //confirmPassword

    if (confirmpassword === "") {
      setConfirmpasswordempty(true);
      setConfirmpasswordErrorMsg("Confirm Password is Required");
      check = false;
    } else if (confirmpassword && password) {
      if (password !== confirmpassword) {
        setConfirmpasswordempty(true);
        setConfirmpasswordErrorMsg("Confirm Password Not same as password");
        check = false;
      } else {
        setConfirmpasswordempty(false);
      }
    }

    if (check === true) {
      const data = {
        admin_email: adminemail,
        admin_id: adminid,
        reset_password: password,
        confirm_password: confirmpassword,
      };
      const Response = await ResetPasswordApi(data);
      console.log(Response, "ResponseResponse",data);
      if (Response.status == 200) {
        toast.success(Response.message);
        setTimeout(() => {
          navigate("/");
        }, 3000);
        setLoading(false);
      } else if (Response.status == 400) {
        toast.error(Response.message);
        setLoading(false);
      } else {
        toast.error(Response.message);
        setLoading(false);
      }
    }
  };

  const showPasswordHandler = (flag) => {
    if (flag === "password") {
      setShowPassword(!showPassword);
    } else if (flag === "confirmpassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  const hidePasswordHandler = (flag) => {
    if (flag === "password") {
      setShowPassword(true);
    } else if (flag === "confirmpassword") {
      setShowConfirmPassword(true);
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmpassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="forgot_Password_Main">
        <Row className="Forgot_Password public_Routes">
          <Col md="6" className="login-first">
            <div>
              <Image
                loading="lazy"
                src={forgot}
                className="confused-dog-image"
              ></Image>
            </div>
          </Col>
          <Col md="6" className="login-second">
            <div className="forgot_Password_Head">
              <Image loading="lazy" src={Logo} className="login-logo"></Image>
              <p>Create New password</p>
              <h5>
                <i>
                  Create a strong password with a mix of upper case letters,
                  alphanumeric and symbols containing at least 8 characters.
                </i>
              </h5>
            </div>
            <Row>
              <div>
                <Form className="password_Fields" onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-3 toggle_Password"
                    controlId="formBasicPassword"
                    style={{ position: "relative" }}
                  >
                    <Form.Label className="form-label-custom">
                      New Password <span className="star-required">*</span>
                    </Form.Label>
                    <Form.Control
                      placeholder="New Password"
                      className="custom-formcontrol"
                      value={password}
                      onChange={handleChangePassword}
                      type={showPassword ? "text" : "password"}
                    />
                    {password !== "" &&
                      (showPassword ? (
                        <FaEyeSlash
                          onClick={() => togglePasswordVisibility("password")}
                          className="Faeye"
                        />
                      ) : (
                        <FaEye
                          onClick={() => togglePasswordVisibility("password")}
                          className="Faeye"
                        />
                      ))}
                    <p className="err-msg">
                      {passwordempty ? passwordErrorMsg : ""}
                    </p>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 toggle_Password"
                    controlId="formBasicPassword"
                    style={{ position: "relative" }}
                  >
                    <Form.Label className="form-label-custom">
                      Confirm Password <span className="star-required">*</span>
                    </Form.Label>
                    <Form.Control
                      placeholder="Confirm Password"
                      className="custom-formcontrol"
                      value={confirmpassword}
                      onChange={handleChangeConfirmPassword}
                      type={showConfirmPassword ? "text" : "password"}
                    />
                    {confirmpassword !== "" &&
                      (showConfirmPassword ? (
                        <FaEyeSlash
                          onClick={() =>
                            togglePasswordVisibility("confirmpassword")
                          }
                          className="Faeye"
                        />
                      ) : (
                        <FaEye
                          onClick={() =>
                            togglePasswordVisibility("confirmpassword")
                          }
                          className="Faeye"
                        />
                      ))}
                    <p className="err-msg">
                      {confirmpasswordempty ? confirmpasswordErrorMsg : ""}
                    </p>
                  </Form.Group>
                  <div className="password-login-div">
                    <button
                      onSubmit={handleSubmit}
                      className="login-button btn btn-primary"
                    >
                      Save
                    </button>
                    <BackArrow to="/" />
                  </div>
                </Form>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateNewPassword;
