import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import { AddFoodApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";

const CreateModal = ({
  images,
  head,
  detail,
  button,
  placeholder,
  onHide,
  ...props
}) => {
  const [foodName, setFoodName] = useState("");
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleChangeFoodName = (e) => {
    e.preventDefault();
    setFoodName(e.target.value);
  };
  const addFood = async (e) => {
    e.preventDefault();
    let check = true;
    if (!foodName) {
      setErrorEmpty(true);
      setErrorMsg("Food Name should not be empty");
      check = false;
    } else if (foodName) {
      if (/^\s+$/.test(foodName) === true) {
        setErrorEmpty(true);
        setErrorMsg("Food Name should not be empty");
        check = false;
      } else {
        setErrorEmpty(false);
      }
    } else {
      setErrorMsg(false);
    }

    if (check === true) {
      const data = {
        food_name: foodName,
      };
      const Response = await AddFoodApi(data);
      if (Response.status == 200) {
        toast.success(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (Response.status == 400) {
        toast.error(Response.message);
      } else {
        toast.error(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  const handleClose = () =>{
    window.location.reload();
  }


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <img
              src={closeButton}
              className="closeButton"
              alt="closeButton"
              onClick={handleClose}
            />
            <div className="modal-image">
              <img
                loading="lazy"
                src={images}
                alt="category image"
                className="modal_Head_Image"
              />
            </div>
            <h3>{head}</h3>
            {/* <h4>{detail}</h4> */}
            <Form onSubmit={addFood}>
              <div className="Modal-input">
                <input
                  type="text"
                  placeholder={placeholder}
                  maxLength={50}
                  value={foodName}
                  onChange={handleChangeFoodName}
                ></input>
              </div>
              <p className="err-msg">{errorEmpty ? errorMsg : ""}</p>
              <button className="modal_Button" onSubmit={addFood}>
                Add
              </button>
            </Form>
            {/* <p className="error-msg">erroe</p><br></br> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CreateModal;
