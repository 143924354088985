import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import closeButton from "../../Assets/Images/close-btn.png";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { AddAdminRoleApi, AdminRoleApi } from "../../Utils/API/ApiCall";
import Item from "antd/es/list/Item";
import { ToastContainer, toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CreateAdminUser = ({ onHide, images, head, button, ...props }) => {
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [adminChangedRole, setAdminChangedRole] = useState(1);

  const [errorNameEmpty, setErrorNameEmpty] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorEmailEmpty, setErrorEmailEmpty] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorPasswordEmpty, setErrorPasswordEmpty] = useState(false);
  const [errorMsgPassword, setErrorMsgPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const showPasswordHandler = () => {
    setShowPassword(false);
  };
  const hidePasswordHandler = () => {
    setShowPassword(true);
  };

  const handleChaneAdminName = (e) => {
    e.preventDefault();
    setAdminName(e.target.value);
  };
  const handleChaneAdminEmail = (e) => {
    e.preventDefault();
    setAdminEmail(e.target.value);
  };
  const handleChaneAdminPassword = (e) => {
    e.preventDefault();
    setAdminPassword(e.target.value);
  };

  useEffect(() => {
    AdminRoleData();
  }, []);

  const AdminRoleData = async () => {
    const Response = await AdminRoleApi();
    setAdminRole(Response.data);
  };

  const handleChangeAdminRole = (e) => {
    e.preventDefault();
    setAdminChangedRole(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let Emailformat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let Passwordformat =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

    let check = true;
    if (!adminName) {
      setErrorNameEmpty(true);
      setErrorMsgName("Name is Required");
      check = false;
    } else if (adminName) {
      if (/^\s+$/.test(adminName) === true) {
        setErrorNameEmpty(true);
        setErrorMsgName("Name is Required");
        check = false;
      } else {
        setErrorNameEmpty(false);
      }
    } else {
      setErrorMsgName(false);
    }

    if (!adminEmail) {
      setErrorEmailEmpty(true);
      setErrorMsgEmail("Email is Required");
      check = false;
    } else if (adminEmail) {
      if (/^\s+$/.test(adminEmail) === true) {
        setErrorEmailEmpty(true);
        setErrorMsgEmail("Email is Required");
        check = false;
      } else if (Emailformat.test(adminEmail) === false) {
        console.log(check, "check");
        setErrorEmailEmpty(true);
        setErrorMsgEmail("Email format wrong");
        check = false;
      } else {
        setErrorEmailEmpty(false);
      }
    } else {
      setErrorMsgEmail(false);
    }

     //password
     if (adminPassword === "") {
      setErrorPasswordEmpty(true);
      setErrorMsgPassword("Password is Required");
      check = false;
    } else if (adminPassword) {
      if (Passwordformat.test(adminPassword) === false) {
        setErrorPasswordEmpty(true);
        setErrorMsgPassword(
          "Password should be Min 1 uppercase letter, Min 1 lowercase letter, Min 1 special character, Min 1 number, Min 8 characters, Max 30 characters."
        );
        check = false;
      } else {
        setErrorPasswordEmpty(false);
      }
    } else {
      setErrorPasswordEmpty(false);
    }

    if (check === true) {
      const data = {
        admin_name: adminName,
        admin_email: adminEmail,
        admin_password: adminPassword,
        admin_role: adminChangedRole,
      };
      const Response = await AddAdminRoleApi(data);
      if (Response.status == 200) {
        toast.success(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (Response.status == 400) {
        toast.error(Response.message);
      } else {
        toast.error(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  const handleClose = () =>{
    window.location.reload();
  }

  return (
    <>
      <div className="Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="admin_User_Modal">
            <img
              src={closeButton}
              className="closeButton"
              alt="closeButton"
              onClick={handleClose}
            />
            <div className="modal-image">
              <img
                loading="lazy"
                src={images}
                alt="category image"
                className="modal_Head_Image"
              />
            </div>
            <h3>{head}</h3>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Admin user name</Form.Label>
                    <Form.Control
                      placeholder="Enter Admin user name"
                      value={adminName}
                      onChange={handleChaneAdminName}
                    />
                    <p className="err-msg">
                      {errorNameEmpty ? errorMsgName : ""}
                    </p>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Admin user email</Form.Label>
                    <Form.Control
                      placeholder="Enter Admin user email"
                      autoComplete="off"
                      value={adminEmail}
                      onChange={handleChaneAdminEmail}
                    />
                    <p className="err-msg">
                      {errorEmailEmpty ? errorMsgEmail : ""}
                    </p>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3 toggle_Password">
                    <Form.Label>Admin user password</Form.Label>
                    <Form.Control
                      placeholder="Enter Admin user password"
                      type={showPassword === true ? "text" : "password"}
                      autoComplete="new-password"
                      value={adminPassword}
                      onChange={handleChaneAdminPassword}
                    />
                    {adminPassword !== "" ? (
                      showPassword === true ? (
                        <FaEye
                          onClick={showPasswordHandler}
                          className="Faeye"
                        />
                      ) : (
                        <FaEyeSlash
                          onClick={hidePasswordHandler}
                          className="Faeye"
                        />
                      )
                    ) : (
                      <></>
                    )}
                    <p className="err-msg">
                      {errorPasswordEmpty ? errorMsgPassword : ""}
                    </p>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Admin role</Form.Label>
                    <Form.Select onChange={(e) => handleChangeAdminRole(e)}>
                      {adminRole?.length > 0
                        ? adminRole.map((item, index) => {
                            return (
                              <option value={item.role_id}>
                                {item.role_name}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <button
                className="modal_Button"
                onSubmit={handleSubmit}
                // onClick={
                //   adminName.length > 0 && adminEmail.length > 0 && adminPassword.length > 0 ? handleSubmit : ""}
              >
                {button}
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default CreateAdminUser;
