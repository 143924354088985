import Modal from "react-bootstrap/Modal";
import closebtn from "../../Assets/Images/close-btn.png";
import { EmailContent } from "./EmailContent";
import { useEffect, useState } from "react";
import { PreviousEmailApi } from "../../Utils/API/ApiCall";

export const EmailPopup = ({ showAgain, onHide, getuserId, getIndex, ...props }) => {

  const [emailShow, setEmailShow] = useState(false);
  const [previousEmailData, setPreviousEmailData] = useState([])
  const [indexer, setIndexer] = useState("")

  useEffect (()=>{
    PreviousEmailList()
  },[props])

  const PreviousEmailList = async() =>{
    const data ={
      user_id:getuserId
    }
    const Response = await PreviousEmailApi(data)
    //  console.log(Response.data,"Response")
    setPreviousEmailData(Response.data)
  }

  const emailContentHandler = () => {
    setEmailShow(true);
    onHide();
  };


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-Modal"
      >
        <Modal.Body className="email_Modal">
          <Modal.Header>
            <p>Previous Email list</p>
            <img
              loading="lazy"
              alt="close-btn"
              className="closeButton"
              src={closebtn}
              onClick={onHide}
            />
          </Modal.Header>
          <div className="email_Modal_Table">
            <table>
              <thead>
                <tr>
                  <th>Email Subject</th>
                  <th>Sent By</th>
                  <th>Date</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {previousEmailData && previousEmailData.length > 0  ? (
                  previousEmailData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <p
                            className="subject_td"
                            onClick={() => {
                              emailContentHandler();
                              setIndexer(item.notification_id)
                            }
                          }
                          >
                            {item.subject}
                          </p>
                        </td>
                        <td>
                          <p>{item.sent_by}</p>
                        </td>
                        <td>
                          <p>{item.created_at}</p>
                        </td>
                        <td className="action_td">
                          <p className="Open">{item.email_status}</p>
                        </td>
                        {/* <td>
                          <button className="resend_Button">Resend</button>
                        </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={5}>
                    <p className="no_Data_Available">No data available</p>
                  </td>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <EmailContent 
      show={emailShow}
      getIndex = {indexer}
      onHide={() => setEmailShow(false)} />
    </>
  );
};
