import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";

const BackArrow = ({ clear, ...props }) => {
  return (
    <p className="back-arrow dashboard">
      <Link to={props.to}>
        <MdKeyboardArrowLeft className="arrow-left" />
        Back
      </Link>
    </p>
  );
};

export default BackArrow;
