import React from "react";
import logsImage from "../../Assets/Images/User Icon FT.svg";
import {  Row } from "react-bootstrap";
import editIcon from "../../Assets/Images/edit icon.svg";
import EditActivityMessage from "../Modals/EditActivityMessage";
import messages from "../../Assets/Images/messages.png";
import { useState, useEffect } from "react";
import { DeleteActivityMessagesApi, ViewActivityMessagesApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import sortUp from "../../Assets/Images/sortup.png";
import sortDown from "../../Assets/Images/sortdown.png";
import { Modals } from "../Modals/Modals";
import deleteIcon from "../../Assets/Images/delete icon.png";
import Pagination from "../Pagination/Pagination";
import EditModal from "../Modals/EditActivityMessage";

const ActivityMessageTable = ({ SearchValue }) => {
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [indexer, setIndexer] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [activityMessageDatalist, setActivityMessageDatalist] = useState([]);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [editIndexer, setEditIndexer] = useState();
  const [sortClick, setSortClick] = useState(1);
  const [loading, setLoading] = useState(false);




  useEffect(() => {
    if (isDelete == true) {
      handleDelete(indexer);
      setModalShow(false);
      setIsDelete(false);
    }
  }, [isDelete]);

  useEffect(() => {
    setLoading(true);
    ActivityMessageListdata();
  }, [pageNumber, sortClick, SearchValue]);

  const ActivityMessageListdata = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      sort_type: sortClick,
      column_name: "created_at",
      search_value: SearchValue,
    };
    const Response = await ViewActivityMessagesApi(data);
    setLoading(false);
    console.log(Response.data,"Response.data.activity_messages")
    setActivityMessageDatalist(Response.data.activity_messages);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
  };

  const handleDelete = async () => {
    const data = {
      activity_id: indexer,
    };
    const Response = await DeleteActivityMessagesApi(data);
    console.log(Response,"Response")
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleActivityMessagename = async (payload) => {
    const data = {
      activity_id: payload,
    };
    // const Response = await EditPlaceholderBreedApi(data)
  };

  const handleClickEdit = (data) => {
    console.log("data", data);
    setEditModalShow(true);
    handleActivityMessagename(data.activity_id);
    setEditIndexer(data);
  };

  const handleEditHide = () => {
    setEditModalShow(false);
    window.location.reload();
  };
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="TableMain">
        <table>
          <thead>
            <tr>
              <th className="tablehead">
                <div className="tableheaders position-relative">
                  Activity Message List
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody id="TBody">
            {activityMessageDatalist?.length > 0 ? (
              activityMessageDatalist.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.activity_message}</td>
                    <td>
                      <div className="table-action">
                        <img
                          loading="lazy"
                          title="Edit"
                          src={editIcon}
                          onClick={() => {
                            handleClickEdit(item);
                          }}
                        />
                         <img
                          loading="lazy"
                          title="Delete"
                          src={deleteIcon}
                          onClick={() => {
                            setModalShow(true);
                            setIndexer(item.activity_id);
                            // setBreedName(item.breed_name);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={2}>
                <p className="NoDataAvailable">No data available!</p>
              </td>
            )}
          </tbody>
        </table>
      </div>
      {totalPageCount > 10 ? (
        <>
          <Pagination
            Limit={limit}
            SetPageNumber={setPageNumber}
            TotalPageCount={totalPageCount}
          />
        </>
      ) : (
        <></>
      )}
  <Modals
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsDelete={setIsDelete}
        head={"Are you sure you want to delete?"}
      />
      <EditModal
        show={editModalShow}
        onHide={handleEditHide}
        head="Edit the activity message"
        detail="You can edit the activity message"
        // placeholder={breedPlaceholder}
        value={editIndexer}
        editIndexer={editIndexer}
      />
    </>
  );
};

export default ActivityMessageTable;
