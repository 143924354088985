import React, { useState } from "react";
import confusedDog from "../../Assets/Images/confused dog.png";
import loginlogo from "../../Assets/Images/login-logo.png";
import Form from "react-bootstrap/Form";
import { Row, Button } from "react-bootstrap";
import ErrorLabel from "../../Components/ErrorLabel/ErrorLabel";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { ForgotPasswordApi } from "../../Utils/API/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [errorEmailEmpty, setErrorEmailEmpty] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("");

  const handleChaneAdminEmail = (e) => {
    e.preventDefault();
    setAdminEmail(e.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let Emailformat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let check = true;

      if (!adminEmail) {
        setErrorEmailEmpty(true);
        setErrorMsgEmail("Email should not be empty");
        check = false;
      } else if (adminEmail) {
        if (/^\s+$/.test(adminEmail) === true) {
          setErrorEmailEmpty(true);
          setErrorMsgEmail("Email should not be empty");
          check = false;
        } else if (Emailformat.test(adminEmail) === false) {
          console.log(check, "check");
          setErrorEmailEmpty(true);
          setErrorMsgEmail("Email format wrong");
          check = false;
        } else {
          setErrorEmailEmpty(false);
        }
      } else {
        setErrorMsgEmail(false);
      }

      if (check === true) {
        setLoading(true);
        const data = {
          admin_email:adminEmail
        };
        const Response = await ForgotPasswordApi(data);
        // console.log(Response.data[0].admin_id,"Response")
        if (Response.status == 200) {
          localStorage.setItem("adminemail", adminEmail);
          localStorage.setItem("adminid", Response.data[0].admin_id);
          toast.success(Response.message);
          setTimeout(() => {
            navigate("/verifyOTP");
          }, 3000);
          setLoading(false);
        } else if (Response.status == 400) {
          toast.error(Response.message);
          setLoading(false);
        } else {
          toast.error(Response.message);
          setLoading(false);
        }
      }
  }

  return (
    <>
     {loading ? <Loader /> : ""}
      <div className="forgot_Password_Main">
        <div className="Forgot_Password public_Routes">
          <div className="forgot-password-one">
            <img
              loading="lazy"
              src={confusedDog}
              alt="login"
              className="confused-dog-image"
            />
          </div>
          <div className="forgot-password-two">
            <img
              loading="lazy"
              src={loginlogo}
              alt="logo"
              className="login-logo"
            />
            <h3>Forgot Password</h3>
            <p>
              Please enter your registered e-mail ID to receive the verification
              code.
            </p>
            <Row>
              <Form className="forgot passwordcheck" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      placeholder="Enter Email Address"
                      autoComplete="off"
                      value={adminEmail}
                      onChange={handleChaneAdminEmail}
                    />
                    <p className="err-msg">
                      {errorEmailEmpty ? errorMsgEmail : ""}
                    </p>
                  </Form.Group>
                <div className="password-login-div">
                <button
                    className="login-button"
                    onSubmit={handleSubmit}
                  >
                    Submit
                  </button>
                  <BackArrow to="/" />
                </div>
              </Form>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
