import React from "react";
import SearchImage from "../../Assets/Images/tabler_search.svg";

const Search = ({ SearchValFuc, SearchValue, handleSearch }) => {
  const onSearch = (e) => {
    let searchVal = e.target.value;
    SearchValFuc(searchVal);
    if (!searchVal) {
      SearchValFuc("");
    }
  };
  return (
    <div className="Search">
      <div>
        <img loading="lazy" src={SearchImage} alt="Search" />
        <input
          type="text"
          placeholder="Search"
          onChange={onSearch}
          value={SearchValue}
        ></input>
      </div>
      <button onClick={handleSearch}>SEARCH</button>
    </div>
  );
};


export default Search;

// const [inputValue, setInputValue] = useState("")

//   const onSearch = (e) => {
//     setInputValue(e.target.value)
//   }
//   const handleSearcher = () => {
//     SearchValFuc(inputValue);
//     if (!inputValue) {
//       SearchValFuc("");
//     }
//   }

//   useEffect(() => {
//     if (inputValue === "") {
//       window.location.reload()
//     }
//   }, [setInputValue])
//   return (
//     <div className="Search">
//       <div>
//         <img loading="lazy" src={SearchImage} alt="Search" />
//         <input
//           type="text"
//           placeholder="Search"
//           onChange={onSearch}
//         value={SearchValue}
//         ></input>
//       </div>
//       <button onClick={handleSearcher}>SEARCH</button>
//     </div>
//   );
// };