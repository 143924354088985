import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import deleteIcon from "../../Assets/Images/delete icon.png";
import editIcon from "../../Assets/Images/edit icon.svg";
import Pagination from "../Pagination/Pagination";
import { AdminUsersTableApi } from "../../Utils/API/AdminUsersTableApi";
import { Modals } from "../Modals/Modals";
import CreateAdminUser from "../Modals/CreateAdminUser";
import modalImg from "../../Assets/Images/dog bow.png";
import { DeleteUsersApi, ViewAdminUsersApi } from "../../Utils/API/ApiCall";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import sortUp from "../../Assets/Images/sortup.png";
import sortDown from "../../Assets/Images/sortdown.png";
import EditModal from "../Modals/EditAdminUsers";

const AdminUsersTable = ({ SearchValue, adminName }) => {
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [indexer, setIndexer] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [adminUserDatalist, setAdminUserDatalist] = useState([]);
  const [limit, setLimit] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [editIndexer, setEditIndexer] = useState();
  const [sortClick, setSortClick] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mainAdminName, setMainAdminNmae] = useState("");
  const [tableHeadClick, setTableHeadClick] = useState("admin_name");

  useEffect(() => {
    if (isDelete == true) {
      handleDelete(indexer);
      setModalShow(false);
      setIsDelete(false);
    }
  }, [isDelete]);

  useEffect(() => {
    setLoading(true);
    AdminUsersListdata();
  }, [pageNumber, sortClick, SearchValue]);

  const AdminUsersListdata = async () => {
    const data = {
      limit: 10,
      current_page: SearchValue ? 0 : pageNumber,
      sort_type: sortClick,
      column_name: "created_at",
      search_value: SearchValue,
    };
    const Response = await ViewAdminUsersApi(data);
    setLoading(false);
    setAdminUserDatalist(Response.data.activity_type);
    setLimit(Response.data.limit);
    setTotalPageCount(Response.data.total_rows);
  };

  const handleDelete = async () => {
    const data = {
      admin_id: indexer,
    };
    const Response = await DeleteUsersApi(data);
    if (Response.status === 200) {
      toast.success(Response.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleAdminUsers = async (payload) => {
    const data = {
      admin_id: payload,
    };
    // const Response = await EditPlaceholderFoodApi(data)
  };
  const handleClickEdit = (data) => {
    // console.log("data", data);
    setEditModalShow(true);
    handleAdminUsers(data.admin_id);
    setEditIndexer(data);
  };

  const handleEditHide = () => {
    setEditModalShow(false);
    window.location.reload();
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="TableMain">
        <table>
          <thead>
            <tr>
              <th className="tablehead">
                <div className="tableheaders">
                  First Name
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(1);
                        setTableHeadClick("admin_name");
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(2);
                        setTableHeadClick("admin_name");
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">
                  Email ID
                  <div className="SortButton">
                    <img
                      src={sortUp}
                      onClick={() => {
                        setSortClick(2);
                        setTableHeadClick("admin_email");
                      }}
                    />
                    <img
                      src={sortDown}
                      onClick={() => {
                        setSortClick(1);
                        setTableHeadClick("admin_email");
                      }}
                    />
                  </div>
                </div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Status</div>
              </th>
              <th className="tablehead">
                <div className="tableheaders">Action</div>
              </th>
            </tr>
          </thead>
          <tbody id="TBody">
            {adminUserDatalist?.length > 0 ? (
              adminUserDatalist.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.admin_name}</td>
                    <td>{item.admin_email}</td>
                    <td>{item.admin_role}</td>
                    <td>
                      <div className="table-action">
                        <img
                          loading="lazy"
                          title="Delete"
                          src={deleteIcon}
                          onClick={() => {
                            setModalShow(true);
                            setIndexer(item.admin_id);
                            setMainAdminNmae(item.admin_name);
                          }}
                        />
                        <img
                          loading="lazy"
                          title="Edit"
                          src={editIcon}
                          onClick={() => {
                            handleClickEdit(item);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td className="NoDataAvailable" colSpan={7}>
                <p className="NoDataAvailable">No data available!</p>
              </td>
            )}
          </tbody>
        </table>
        {totalPageCount > 10 ? (
          <>
            <Pagination
              Limit={limit}
              SetPageNumber={setPageNumber}
              TotalPageCount={totalPageCount}
            />
          </>
        ) : (
          <></>
        )}
        <Modals
          show={modalShow}
          onHide={() => setModalShow(false)}
          setIsDelete={setIsDelete}
          head={
            "Are you sure you want to delete " + mainAdminName + " as an admin?"
          }
        />
        <EditModal
          show={editModalShow}
          onHide={handleEditHide}
          head="Edit admin user name"
          images={modalImg}
          button="Save"
          value={editIndexer}
        />
      </div>
    </>
  );
};

export default AdminUsersTable;
