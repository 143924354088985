import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate, NavLink, Link } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import breed from "../../Assets/Images/Navigation icons/breeds.svg";
import color from "../../Assets/Images/color.png";
import personality from "../../Assets/Images/Navigation icons/personality.svg";
import toy from "../../Assets/Images/Navigation icons/toys.svg";
import food from "../../Assets/Images/Navigation icons/food.svg";
import activities from "../../Assets/Images/Navigation icons/activity.svg";
import users from "../../Assets/Images/Navigation icons/app users.svg";
import notifications from "../../Assets/Images/Navigation icons/notifications.svg";
import dashboard from "../../Assets/Images/Navigation icons/dashboard.svg";
import AdminUsers from "../../Assets/Images/Navigation icons/Admin users.svg";
import logout from "../../Assets/Images/logoutImg.png";
import messages from "../../Assets/Images/Navigation icons/messages.svg";
import activityMessages from "../../Assets/Images/Navigation icons/activity messages.svg";
import activityMotivation from "../../Assets/Images/Navigation icons/activity_motivation.svg";
import healthMessages from "../../Assets/Images/Navigation icons/health message.svg";
import { NavDropdown } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Layout = (props) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("tab1");
  const [activeClass, setActiveClass] = useState(false);
  const [toggleClass, setToggleClass] = useState(false);

  const LogoutHandler = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const showToggleNavigation = (flag) => {
    if (flag === "enter" || flag === "clicked") {
      setToggleClass(true);
      setActiveClass(true);
    } else if (flag === "leave") {
      setToggleClass(false);
      setActiveClass(false);
    } else if (flag === "inside" || flag === "clicked") {
      setActiveClass(false);
      setToggleClass(true);
    }
  };

  useEffect(() => {
    const URL = window.location.href;
    const SplitUrl = URL.split("/");
    const EndPath = SplitUrl[3];

    if (EndPath === "messages") {
      setToggleClass(true);
    }
  }, [showToggleNavigation]);

  useEffect(() => {
    const URL = window.location.href;
    const SplitUrl = URL.split("/");
    const EndPath = SplitUrl[3];
    const EndPathLast = SplitUrl[4];

    if (EndPath === "all-logs") {
      setActiveTab("tab0");
    } else if (EndPath === "dashboard") {
      setActiveTab("tab1");
    } else if (EndPath === "breed") {
      setActiveTab("tab2");
    } else if (EndPath === "color") {
      setActiveTab("tab3");
    } else if (EndPath === "personality") {
      setActiveTab("tab4");
    } else if (EndPath === "toys") {
      setActiveTab("tab5");
    } else if (EndPath === "food") {
      setActiveTab("tab6");
    } else if (EndPath === "activities") {
      setActiveTab("tab7");
    } else if (EndPath === "users") {
      setActiveTab("tab8");
    } else if (EndPath === "notifications") {
      setActiveTab("tab9");
    } else if (EndPath === "adminusers") {
      setActiveTab("tab10");
    } else if (EndPath === "messages") {
      setActiveTab("tab11");
    } else if (EndPathLast === "health") {
      setActiveTab("tab12");
      setToggleClass(true);
    } else if (EndPathLast === "activity") {
      setActiveTab("tab13");
      setToggleClass(true);
    }
    else if (EndPathLast === "activitymotivation") {
      setActiveTab("tab14");
      setToggleClass(true);
    }
  }, []);

  return (
    <div className="Layout">
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/dashboard">
            <img loading="lazy" src={logo} className="logoImage" />
            FleaTiger
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto Navtabs">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive || activeTab === "tab0" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={dashboard} />
                Dashboard
              </NavLink>
              <NavLink
                to="/breeds"
                className={({ isActive }) =>
                  isActive || activeTab === "tab2" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={breed} />
                Breeds{" "}
              </NavLink>
              <NavLink
                to="/personality"
                className={({ isActive }) =>
                  isActive || activeTab === "tab4" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={personality} />
                Personality
              </NavLink>
              <NavLink
                to="/toys"
                className={({ isActive }) =>
                  isActive || activeTab === "tab5" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={toy} />
                Toys
              </NavLink>
              <NavLink
                to="/food"
                className={({ isActive }) =>
                  isActive || activeTab === "tab6" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={food} />
                Food
              </NavLink>
              <NavLink
                to="/activities"
                className={({ isActive }) =>
                  isActive || activeTab === "tab7" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={activities} />
                Activities
              </NavLink>
              <a
                className={
                  activeClass === true
                    ? "active messages_Tab"
                    : "inactive messages_Tab"
                }
                onMouseEnter={() => showToggleNavigation("enter")}
                onMouseLeave={() => showToggleNavigation("leave")}
              >
                <img loading="lazy" src={messages} />
                Messages
                <MdOutlineKeyboardArrowDown className="nav-arrow" />
              </a>
              <div
                className="toggle_Navigation"
                onMouseEnter={() => showToggleNavigation("inside")}
                onMouseLeave={() => showToggleNavigation("leave")}
                style={
                  toggleClass === true
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <NavLink
                  to="/messages/health"
                  className={({ isActive }) =>
                    isActive || activeTab === "tab12" ? "active" : "inactive"
                  }
                  onClick={() => showToggleNavigation("clicked")}
                >
                  <img loading="lazy" src={healthMessages} />
                  Health
                </NavLink>
                <NavLink
                  to="/messages/activity"
                  className={({ isActive }) =>
                    isActive || activeTab === "tab13" ? "active" : "inactive"
                  }
                  onClick={() => showToggleNavigation("clicked")}
                >
                  <img loading="lazy" src={activityMessages} />
                  Activity
                </NavLink>
                <NavLink
                  to="/messages/activitymotivation"
                  className={({ isActive }) =>
                    isActive || activeTab === "tab14" ? "active" : "inactive"
                  }
                  onClick={() => showToggleNavigation("clicked")}
                >
                  <img loading="lazy" src={activityMotivation} />
                  Motivation
                </NavLink>
              </div>
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  isActive || activeTab === "tab8" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={users} />
                App Users
              </NavLink>
              <NavLink
                to="/notifications"
                className={({ isActive }) =>
                  isActive || activeTab === "tab9" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={notifications} />
                Email Management
              </NavLink>
              <NavLink
                to="/adminusers"
                className={({ isActive }) =>
                  isActive || activeTab === "tab10" ? "active" : "inactive"
                }
              >
                <img loading="lazy" src={AdminUsers} />
                Admin Users
              </NavLink>
            </Nav>
            <button className="logout-btn" onClick={LogoutHandler}>
              <img
                loading="lazy"
                alt="logout"
                className="logout"
                src={logout}
              />
              Logout
            </button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Layout;
