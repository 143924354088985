import React, { useState } from "react";
import Navigation from "../../Components/Navigation/Navigation";
import BackArrow from "../../Components/BackArrow/BackArrow";
import Search from "../../Components/Search/Search";
import modalImg from "../../Assets/Images/dog bow.png";
import SearchBarResults from "../../Components/Search/SearchBarResults";
import AdminUsersTable from "../../Components/Table/AdminUsersTable";
import CreateAdminUser from "../../Components/Modals/CreateAdminUser";

const AdminUsers = () => {
  // modal
  const [modalShow, setModalShow] = useState(false);
  // search
  const [search, setSearch] = useState("");
  return (
    <div>
      <Navigation />
      <div className="Admin-user-Main">
        <BackArrow to="/activities" />
        <div className="Admin-user-Div">
          <h3>Admin Users</h3>
          <div className="search-modal">
            <Search SearchValFuc={setSearch} SearchValue={search} />
            <button
              className="create"
              variant="primary"
              onClick={() => {
                setModalShow(true);
              }}
            >
              + ADD ADMIN USER
            </button>
          </div>
          <SearchBarResults SearchValue={search} />
        </div>
        <AdminUsersTable SearchValue={search} />
      </div>
      <CreateAdminUser
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        head="Add a new admin user"
        detail="You can add the new admin user here"
        placeholder="Enter user"
        images={modalImg}
        button="Add"
      />
    </div>
  );
};

export default AdminUsers;
